.amplifier-cases{
  background: black;
  @include paddingY(96);

  @include mq(medium){
    @include paddingY(120);
  }

  @include mq(tablet){
    @include paddingY(160);
  }
  &-wrapper{

    .amplifier-section-title{
      @include marginB(48);

      @include mq(medium){
        @include marginB(80);
      }
      @include mq(hd){
        @include marginB(120);
      }
    }
    &-examples{
      @include flexbox($direction: column);
      gap:32px;
      @include mq(medium){
        gap: 64px;
      }
      @include mq(tablet){
        gap: 80px;
      }
      @include mq(hd){
        gap: 120px;
      }

      &-item{

        &-video{
          height: 184px;

          @include mq(medium){
            height: 396px;
          }

          @include mq(tablet){
            height: 540px;
          }

          @include mq(hd){
            height: 675px;
          }
        }
        &-description{
          @include flexbox($direction: column, $alignItems:flex-start);
          background: linear-gradient(259deg, rgba(0, 133, 255, 0.06) 0%, rgba(0, 133, 255, 0.19) 42.31%, rgba(0, 133, 255, 0.25) 100%);
          padding: 24px 16px;
          gap: 12px;
          @include mq(medium){
            padding: 24px;
            gap:24px;
          }
          @include mq(tablet){
            flex-direction: row;
            justify-content: space-between;
            padding: 40px 32px;
          }

          h5{
            margin: unset;
            color: $white;
            font-family: 'Montserrat',sans-serif;
            @include get-font(16);
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: -0.32px;
            width: 100%;

            span{
              color: #3485F7;
            }

            &:before{
              content: '';
              width: 120px;
              height: 4px;
              border-radius: 0 4px 4px 0;
              background: linear-gradient(270deg, #3485F7 0%, rgba(52, 133, 247, 0.00) 100%);
              display: block;
              @include marginB(12)
            }
            @include mq(medium){
              @include get-font(24);
              letter-spacing: -0.48px;
            }
            @include mq(tablet){
              width: 45%;
            }
            @include mq(hd){
              @include get-font(32);
              letter-spacing: -0.64px;
            }
          }

          p{
            margin: unset;
            width: 100%;
            color: $white;
            font-family: 'Montserrat', sans-serif;
            @include get-font(14);
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.42px;

            @include mq(medium){
              @include get-font(16);
              letter-spacing: -0.48px;
            }
            @include mq(tablet){
              width: 45%;
            }
            @include mq(hd){
              @include get-font(20);
              letter-spacing: -0.6px;
            }


          }
        }
      }
      &-link{

        @include flexbox($alignItems:center, $direction: column);

        .logo-image{
          width: 194px;
          @include marginB(24);

          @include mq(medium){
            width: 277px;
            @include marginB(32);

          }
          @include mq(tablet){
            width: 416px;
          }

          img{
            width: 100%;
          }

        }

        .cta-link{
          width: 100%;
          text-align: center;
          background: #3485F7;
          padding: 16px;
          @include mq(medium){
            width: 580px;
            padding: 24px;

          }
          @include mq(tablet){
            padding: 32px;
          }

          p{
            display: inline;
            position: relative;
            margin: unset;
            color: $white;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            @include get-font(32);
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.96px;
            text-transform: capitalize;

            @include mq(medium){
              @include get-font(40);
              letter-spacing: -1.2px;
            }

            @include mq(tablet){
              @include get-font(48);
              letter-spacing: -1.44px;
            }
            span{
              &:nth-child(2){
                font-weight: 500;
              }
              &:nth-child(3){
                font-weight: 500;
                color: rgba(255, 255, 255, 0.75);
              }

            }
            &:after{
              position: absolute;
              right: -45px;
              bottom: 0;
              content: '';
              display: block;
              width: 40px;
              height: 40px;

              background: url("asset/image/amplifier/arrow.svg");
              background-position: center center;
              background-size: 100% 100%;
              @include mq(medium){
                width: 56px;
                height: 56px;
                right: -61px;

              }
              @include mq(tablet){
                width: 64px;
                height: 64px;
                right: -69px;
              }

            }
          }
          &:hover{
            background:#285391;
          }
        }

      }
    }
  }
}
