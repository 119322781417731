.invaders-page-resources{
  display: flex;
  justify-content: center;
  @include paddingT(48);
  @include paddingB(48);

  @include mq(medium){
    @include paddingT(82);
    @include paddingB(82);
  }

  @include mq(tablet){
    @include paddingT(99);
  }

  @include mq(hd){
    @include paddingT(132);
    @include paddingB(100);
  }

  @include mq(bigger){
    @include paddingT(198);
  }

  position: relative;

  &-bg{
    position: absolute;
    top: 0;
    //left: 50%;
    //transform: translateX(-50%);
    transform: translate3d(0, 0, 0);

    width: 125%;
    min-height: 999px;
    border-radius: 2422px;
    background: radial-gradient(72.38% 50% at 50% 50%, rgba(254, 85, 1, 0.75) 0%, rgba(254, 85, 1, 0.00) 100%);
    filter: blur(300px);

    @include mq(medium){
      filter: blur(499.5px);

    }
  }

  &-wrapper{
    position: relative;
    z-index: 10;
    @include flexbox($direction: column, $alignItems: center, $justifyContent: space-between);
    @include mq(tablet){
      gap: 80px;
      flex-direction: row;
    }

    &-bg{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: .05;
      max-width: 226px;

      @include mq(medium){
        max-width: 301px;
      }
      @include mq(tablet){
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 401px;
      }
      @include mq(hd){
        max-width: 535px;
      }
      @include mq(bigger){
        max-width: 714px;
      }


      img{
        max-width: 100%;
      }
    }
    &-title{
      z-index: 3;
      @include flexbox($direction: column, $alignItems: flex-start);
      gap: 24px;
      flex: 0 1 auto;
      max-width: 1180px;
      @include mq(tablet){
        gap: 32px;
      }
      &-image{
        max-width: 50px;
        @include mq(hd){
          max-width: 66px;
        }

        @include mq(bigger){
          max-width: 90px;
        }

        img{
          max-width: 100%;
        }
      }

      h3 {
        z-index: 3;
        color: #FFFFFF;
        @include get-font(24);
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        margin: unset;

        span {
          color: #FF7632;
        }

        @include mq(medium) {
          @include get-font(32);
          line-height: 40px;
          letter-spacing: -0.32px;
        }

        @include mq(hd) {
          @include get-font(40);
          line-height: 48px;
          letter-spacing: -0.4px;
        }

        @include mq(bigger) {
          @include get-font(64);
          line-height: 72px;
          letter-spacing: -0.64px;
        }


      }
      p {
        z-index: 3;

        color: rgba(255, 255, 255, 0.75);
        @include get-font(16);
        font-weight: 500;
        line-height: 150%; /* 36px */
        letter-spacing: -0.32px;
        max-width: 1050px;
        margin: 0 auto;

        @include mq(hd) {
          @include get-font(20);
          letter-spacing: -0.4px;
        }
        @include mq(bigger) {
          @include get-font(24);
          letter-spacing: -0.48px;
        }

      }
    }

    &-gif{
      //max-width: 576px;
      //min-width: 576px;
      flex: 0 1 auto;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @include flexbox($direction: row, $alignItems: center, $justifyContent: center);
      position: relative;
      max-width: 224px;

      @include mq(hd){
        max-width: 332px;
      }
      @include mq(bigger){
        max-width: 476px;
      }

      img{
        margin: 0 auto;
        max-width: 100%;
      }

      .drop-image{
        z-index: 4;
      }

      .shadow-image{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 200px;

        @include mq(hd){
          max-width: 264px;
          bottom: 30px;

        }
        @include mq(bigger){
          max-width: 353px;
          bottom: 45px;
        }

      }

    }
  }
}
