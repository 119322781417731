.partners {
  @keyframes gap-animation-partners {
    from{
      gap: 0;
    }
    to{
      gap: 12px;
    }
  }
  @include mq(medium){
    @keyframes gap-animation-partners {
      from{
        gap: 0;
      }
      to{
        gap: 16px;
      }
    }
  }
  @include mq(hd){
    @keyframes gap-animation-partners {
      from{
        gap: 0;
      }
      to{
        gap: 64px;
      }
    }
  }

  .heading-block{
    h2{
      br{
        @include mq(fullHD){
          display: none;
        }
      }
    }
  }
  &-wrapper {


    @include flexbox($justifyContent: flex-start, $direction: column-reverse);
    gap: 0;
    @include paddingT(48);
    @include paddingB(96);

    @include mq(mobile) {
      @include paddingT(64);
      @include paddingB(128);
    }

    @include mq(medium) {
      flex-direction: row-reverse;
      @include paddingT(90);
      @include paddingB(200);
    }

    &-logo {
      background: $black;
      @include paddingY(60);
      @include paddingX(85);
      @include flexbox($justifyContent: center, $alignItems: center);
      max-width: 100%;
      height: 190px;
      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include mq(medium) {
        max-width: 32%;
        flex-grow: 1;
        @include paddingY(35);
        @include paddingX(60);

        height: 126px;
      }
      @include mq(tablet) {
        max-width: 32%;
        flex-grow: 1;
        @include paddingX(80);

        height: 172px;
      }

      @include mq(hd) {
        //@include paddingY(80);
        height: 226px;

      }
      @include mq(fullHD) {
        //@include paddingY(80);
        height: 320px;

      }

    }
  }
  &.animated{
    .partners-wrapper{
      animation: gap-animation-partners .4s ease-in both;
      animation-delay: 0s ;
    }
  }
}
