.vacancies {
  position: sticky;
  @keyframes fade-in-left-vacancy {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right-vacancy {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  .heading-block{
    h2{
      br{
        @include mq(medium){
          display: none;
        }
      }
    }
  }
  &-pinner{
    position: relative;

    &-mask{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
  &-wrapper {


    @include marginT(64);
    @include paddingB(96);

    //@include mq(mobile) {
    //  @include paddingB(128);
    //}
    @include mq(medium) {
      @include marginT(86);
      @include paddingB(164);
    }
    @include mq(tablet) {
      @include marginT(120);
      @include paddingB(200);

    }


    &-block {
      @include flexbox($direction: column);
      gap: 24px;
      &:nth-child(1) {
        @include paddingX(0);
        @include marginB(24);
        @include mq(medium){
          @include marginB(24);

        }
        @include mq(tablet){
          @include marginB(0);
          padding-right: 2rem;

        }

        @media (min-width: 640px) {
        }
      }
      &:nth-child(2) {
        @include paddingX(0);

        @include mq(medium){
          @include marginB(24);
        }
        @include mq(tablet){
          padding-left: 2rem;
        }


      }
      @include mq(medium){
        gap: 40px;

      }
      &__item {
        opacity: 0;
        @include flexbox($justifyContent: space-between, $alignItems: flex-end);
        @include paddingY(24);
        border-bottom: 4px solid $black;
        cursor: pointer;

        .location {
          padding-left: 2rem;

          position: relative;
          @include marginB(20);

          span {
            font-family: "IBM Plex Mono", sans-serif;
            font-weight: 600;
            letter-spacing: 1.2px;
            @include mq(small) {
              @include get-font(12);
            }
            @include mq(medium) {
              @include get-font(16);
              letter-spacing: 2.4px;
            }
            @include mq(hd) {
              @include get-font(24)
            }
          }

          &::before {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: $black;
            position: absolute;
            left: 0;
            top: calc(50% - 8px)
          }
        }

        .arrow {
          width: 32px;
          height: 32px;

          img {
            width: 100%;
          }
        }

        &:hover {
          border-color: $accent;

          .arrow {
            background-color: $accent;

            img {
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(209deg) brightness(107%) contrast(100%)
            }
          }
        }
      }

      .btn-primary {
        opacity: 0;


        @include marginT(10);
        margin-left: auto;
        @include mq(medium) {
          margin-right: 60px;
        }

        @include mq(medium) {
          @include marginT(8);
        }
        @include mq(tablet){
          @include marginT(16);
          margin-right: auto;
          margin-top: 1rem;
          margin-left: unset;
        }
        @include mq(hd){
          @include marginT(40);

        }

      }
    }
  }

  &.animated{
    .vacancies-wrapper-block{
      &:nth-child(1) {
        .vacancies-wrapper-block__item{
          animation: fade-in-left-vacancy 0.6s ease-in-out both;

          &:nth-child(2){
            animation-delay: .3s;
          }
        }
      }

      &:nth-child(2) {

        .vacancies-wrapper-block__item{
          animation: fade-in-right-vacancy 0.6s ease-in-out both;

          &:nth-child(2){
            animation-delay: .3s;
          }
        }
      }
    }
    .btn-primary{
      animation: slide-in-bottom 1s ease-in-out both;
    }
  }
}
