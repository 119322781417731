.invaders-page-enemies {
  @include paddingT(115);
  @include paddingB(48);

  @include mq(medium){
    @include paddingT(108);
    @include paddingB(82);
  }

  @include mq(tablet){
    @include paddingT(30);
  }

  @include mq(hd){
    @include paddingT(230);
    @include paddingB(100);
  }

  @include mq(bigger){
    @include paddingT(190);
  }

  &-wrapper {
    position: relative;
    z-index: 10;
    @include flexbox($direction: column, $alignItems: center);

    &-title {
      @include flexbox($direction: column, $alignItems: center);
      text-align: center;
      position: relative;
      padding-top: 70px;
      @include marginB(48);

      @include mq(medium) {
        padding-top: 80px;
        @include marginB(64);
      }

      @include mq(tablet) {
        padding-top: 120px;
        @include marginB(80);
      }

      @include mq(hd) {
        padding-top: 180px;
        @include marginB(96);
      }

      @include mq(bigger) {
        padding-top: 210px;
        @include marginB(128);
      }

      .title-bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: .05;
        width: 250px;

        @include mq(medium) {
          width: unset;
          max-width: 268px;
        }

        @include mq(tablet) {
          max-width: 357px;
        }
        @include mq(hd) {
          max-width: 476px;
        }
        @include mq(bigger) {
          max-width: unset;
        }


        img {
          max-width: 100%;
        }
      }

      &-image {
        z-index: 3;
        max-width: 54px;
        @include marginB(25);
        @include mq(hd) {
          max-width: 72px;
          @include marginB(30);

        }
        @include mq(bigger) {
          max-width: 100px;
        }

        img {
          max-width: 100%;
        }
      }

      h3 {
        z-index: 3;
        color: #FFFFFF;
        @include get-font(24);
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        margin: unset;
        @include marginB(26);

        span {
          color: #FF7632;
        }

        @include mq(medium) {
          @include get-font(32);
          line-height: 40px;
          letter-spacing: -0.32px;
          @include marginB(34);

        }

        @include mq(hd) {
          @include get-font(40);
          line-height: 48px;
          letter-spacing: -0.4px;
          @include marginB(42);

        }
        @include mq(bigger) {
          @include get-font(64);
          line-height: 72px;
          letter-spacing: -0.64px;
        }


      }

      p {
        z-index: 3;

        color: rgba(255, 255, 255, 0.75);
        @include get-font(16);
        font-weight: 500;
        line-height: 150%; /* 36px */
        letter-spacing: -0.32px;
        max-width: 1050px;
        margin: 0 auto;

        @include mq(hd) {
          @include get-font(20);
          letter-spacing: -0.4px;
        }
        @include mq(bigger) {
          @include get-font(24);
          letter-spacing: -0.48px;
        }

      }
    }

    &-types {
      @include flexbox($direction: row, $justifyContent: center, $alignItems: flex-start, $wrap: wrap);
      gap: 32px;
      @include mq(medium) {
        gap: 48px 24px;
      }
      @include mq(tablet) {
        gap: 64px 32px;
      }
      @include mq(hd) {
        gap: 112px 182px;
      }
      @include mq(bigger) {
        gap: 94px 176px;
      }


      &-item {
        position: relative;

        @include flexbox($direction: column, $alignItems: center);
        text-align: center;
        background-image: url("asset/image/invaders/text-block-bg.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @include paddingT(164);
        @include paddingB(32);
        @include paddingX(20);
        //flex: 0 1 auto;
        max-width: 328px;

        @include mq(medium) {
          max-width: 340px;
          @include paddingT(190);
          @include paddingX(36);
          @include paddingB(40);
        }
        @include mq(tablet) {
          max-width: 464px;
          @include paddingX(40);
          @include paddingB(48);
          @include paddingT(205);

        }
        @include mq(hd) {
          max-width: 528px;
          @include paddingX(48);
          @include paddingB(64);
          @include paddingT(244);

        }

        @include mq(full) {
          @include paddingT(300);

          @include paddingX(64);
          max-width: 680px;
        }

        .item-gif {
          position: absolute;
          top: 0;
          width: 144px;

          @include mq(medium) {
            width: 170px;
          }
          @include mq(tablet) {
            width: 190px;
          }
          @include mq(hd) {
            width: 220px;
          }
          @include mq(bigger) {
            width: 240px;
          }


          img {
            display: block;
            max-width: 100%;
          }

          video {
            display: block;
            max-width: 100%;
          }

          //&:nth-child(1){
          //  top: 40px
          //}
          //
          //&:nth-child(2){
          //  max-width: 360px;
          //  top: 10px;
          //}

        }

        h6 {
          margin: unset;
          color: $black;
          @include get-font(24);
          font-weight: 700;
          letter-spacing: -.96px;
          z-index: 5;
          @include marginB(16);

          @include mq(medium) {
            @include marginB(24);
            @include get-font(28);
            letter-spacing: -1.12px;
          }

          @include mq(tablet) {
            @include get-font(32);
            letter-spacing: -1.28px;
          }
          @include mq(hd) {
            @include get-font(40);
            letter-spacing: -1.6px;
          }


        }

        p {
          margin: unset;
          color: rgba(31, 31, 31, 0.75);
          @include get-font(14);

          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: -.28px;
          z-index: 5;

          @include mq(hd) {
            @include get-font(16);
            letter-spacing: -.32px;
          }
          @include mq(bigger) {
            @include get-font(20);
            letter-spacing: -.4px;
          }

        }

        &:nth-child(1) {
          @include mq(medium) {
            //margin-top: -40px;
          }

          .item-gif {
            top: 20px;

            @include mq(tablet) {
              top: 10px;
            }
            @include mq(hd) {
              top: 30px;
            }
            @include mq(bigger) {
              top: 40px;
            }

          }
        }

        &:nth-child(2) {
          @include mq(medium) {
            margin-top: 20px;
          }

          .item-gif {
            top: -40px;
            width: 214px;

            @include mq(medium) {
              width: 264px;
              top: -50px;
            }

            @include mq(tablet) {
              width: 284px;
              top: -60px;

            }
            @include mq(hd) {
              width: 360px;
              top: -40px;
            }


          }
        }

        &:nth-child(3) {
          .item-gif {
            top: -40px;
            width: 214px;

            @include mq(medium) {
              width: 264px;
              top: -50px;
            }
            @include mq(tablet) {
              width: 284px;
              top: -60px;

            }
            @include mq(hd) {
              width: 360px;
            }

          }
        }

      }
    }
  }
}
