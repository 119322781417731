

.amplifier-reviews{
  background: linear-gradient(180deg, #3485F7 0%, #2660B2 100%);
  @include paddingY(96);

  @include mq(medium){
    @include paddingY(120);
  }

  @include mq(tablet){
    @include paddingY(160);
  }
  .amplifier-section-title{
    @include marginB(48);

    @include mq(medium){
      @include marginB(80);
    }
    @include mq(hd){
      @include marginB(120);
    }

  }
  &-wrapper{
    &-video{
      height: 184px;
      @include marginB(48);

      @include mq(medium){
        height: 396px;
        @include marginB(80);
      }

      @include mq(tablet){
        height: 540px;
      }

      @include mq(hd){
        @include marginB(120);
        height: 675px;
      }
    }
    &-examples{
      @include flexbox($direction: row, $wrap: wrap, $justifyContent: center, $alignItems: flex-start);
      gap: 24px;

      @include mq(hd){
        gap: 40px;
      }

      h6{
        margin: unset;
        color: $white;
        font-family: 'Montserrat',sans-serif;
        @include get-font(16);
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.32px;
        @include marginB(10);

        @include mq(medium){
          @include get-font(24);
          letter-spacing: -0.48px;
        }
        @include mq(tablet){
          @include get-font(24);
          letter-spacing: -0.48px;
        }
        @include mq(hd){
          @include get-font(32);
          letter-spacing: -0.64px;
        }

      }
      p.position{
        margin: unset;
        color: rgba(255, 255, 255, 0.75);
        font-family: 'Montserrat', sans-serif;
        @include get-font(14);
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.42px;
        @include marginB(12);

        @include mq(medium){
          @include get-font(16);
          letter-spacing: -0.48px;
        }

        @include mq(tablet){
          @include get-font(16);
          letter-spacing: -0.48px;
        }

        @include mq(hd){
          @include get-font(20);
          letter-spacing: -0.6px;
        }

      }
      .text-block{
        &:nth-child(4){
          @include mq(medium){
            @include marginT(-22)
          }
          @include mq(tablet){
            @include marginT(0)
          }
        }
      }
    }
  }
}
