.animated {
    animation-duration: $animate-duration;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  
  .animated.repeat-1 {
    animation-iteration-count: $animate-repeat;
  }
  
  .animated.repeat-2 {
    animation-iteration-count: calc($animate-repeat * 2);
  }
  
  .animated.repeat-3 {
    animation-iteration-count: calc($animate-repeat * 3);
  }
  
  .animated.delay-1s {
    animation-delay: $animate-delay;
  }
  
  .animated.delay-2s {
    animation-delay: calc($animate-delay * 2);
  }
  
  .animated.delay-3s {
    animation-delay: calc($animate-delay * 3);
  }
  
  .animated.delay-4s {
    animation-delay: calc($animate-delay * 4);
  }
  
  .animated.delay-5s {
    animation-delay: calc($animate-delay * 5);
  }
  
  .animated.faster {
    animation-duration: calc($animate-duration / 2);
  }
  
  .animated.fast {
    animation-duration: calc($animate-duration * 0.8);
  }
  
  .animated.slow {
    animation-duration: calc($animate-duration * 2);
  }
  
  .animated.slower {
    animation-duration: calc($animate-duration * 3);
  }