.container {
    position: relative;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;

    @include mq(small) {
        //max-width: $col-sm;
    }

    @include mq(mobile) {
        //max-width: $col-sm;
    }

    @include mq(medium) {
        //max-width: $col-md;
        @include paddingX(32);
    }

    @include mq(large) {
        //max-width: $col-lg;
    }

    @include mq(tablet) {
        //max-width: $col-lg;
        @include paddingX(32);
    }

    @include mq(wide) {
        //max-width: $col-lg;
        @include paddingX(35);
    }

    @include mq(hd) {
        //max-width: $col-xl;
        @include paddingX(35);
    }

    @include mq(full) {
        max-width: $col-xxl;
        @include paddingX(64);
    }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.heading-block {
    display: inline-block;
  h3{
    opacity: 0;
  }
  h2{
    opacity: 0;
  }
  .line {
    transform: scaleX(0);
    width: 100%;
    position: relative;
    background-color: $black;
    &:after {
      transform: scaleY(0);
      content: '';
      width: 100%;
      position: absolute;
      background-color: $accent;
      bottom: 0;
      @include mq(small) {
        height: 6px;
        bottom: -6px;
      }
      @include mq(medium) {
        height: 10px;
        bottom: -10px;
      }
    }
    @include mq(small) {
      height: 12px;
    }
    @include mq(medium) {
      height: 24px;
    }
    @include mq(tablet) {
      height: 24px;
    }
    @include mq(hd) {
      height: 24px;
    }
    @include mq(full) {
      height: 24px;
    }
  }
  &.animated{
      h3{
        animation: fade-in 1.2s ease-in-out both;
      }
    h2{
      animation: slide-in-top 0.5s ease-in-out both;
    }
    .line{
      animation: scale-up-hor-left .8s ease-in-out both;
      &:after{
        animation: scale-up-ver-top 0.5s ease-in-out both;
        animation-delay: .8s;
      }
    }

  }
}


