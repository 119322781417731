.endless-tale-page{
  background: #0A0F19;

  header{
    background-color: #0A0F19;

    @include mq(tablet){
      background-color: $white;
    }
    .wrapper-burger{
      background-color: #4F42D3;
    }
  }
  .display-desktop {
    display: none;
    @include mq(tablet) {
      display: block;
    }
  }
  &-wrapper{
    .container{
      max-width: 1328px;
    }
    h2{
      margin: unset;
      text-transform: unset;
      text-align: center;
      font-family: 'Texturina', sans-serif;
      @include get-font(32);
      font-weight: 700;
      line-height: 140%;
      background: linear-gradient(180deg, #F1E0C1 0%, #D7C5A7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include mq(medium){
        @include get-font(72);
      }
    }
    p{
      color: $white;
      text-align: center;
      font-family: 'Texturina', sans-serif;
      @include get-font(16);
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;
      margin: unset;

      @include mq(medium){
        font-weight: 500;
        @include get-font(28);
        letter-spacing: -.84px;
      }

    }
  }

  .contact{
    background: unset;
    &-title{
      text-align: left;
      position: relative;
      @include marginB(48);
      @include mq(medium){
        @include marginB(100);
        text-align: center;
      }

      h2{
        position: relative;
        z-index: 3;
        color: #FFF;
        font-family: 'Texturina', sans-serif;
        @include get-font(32);
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -.96px;
        margin: unset;
        text-transform: unset;
        @include marginB(20);

        span{
          background: linear-gradient(180deg, #F1E0C1 0%, #D7C5A7 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @include mq(medium){
          @include get-font(58);
          letter-spacing: -1.74px;
          @include marginB(24);
        }
      }
    }

    input, textarea{
      font-family: 'Texturina', sans-serif;
      font-weight: 400;
    }

    .btn-primary{
      border-radius: 20px;
      border: 2px solid #ECD3A4;
      background: linear-gradient(180deg, #4C4BD6 0%, #3332B7 100%);
      box-shadow: 0 10px 50px 0 rgba(111, 110, 228, 0.50);
      font-family: 'Texturina', sans-serif;
      &:hover{
        background-color: #4F42D3;
      }
    }
  }

  .footer{
    background: #1F1F1F;
  }
  section.project {
    min-height: 470px;
    background:$white;
    @include mq(mobile){
      min-height: 650px;
    }
    @include mq(medium) {
      min-height: 795px;
    }
    @include mq(tablet) {
      min-height: 993px;
    }
    @include mq(hd) {
      min-height: 1144px;
    }
    @include mq(full) {
      min-height: 1322px;
    }
    @include mq(bigger){
      min-height: 1472px;
    }
    .project-bg {

      &:after {
        background-color: #0A0F19;
        height: 210px;

        box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 20px 0px 0px #1F1F1F;

        @include mq(mobile){
          height: 290px;
        }
        @include mq(medium){
          height: 350px;
        }
        @include mq(tablet){
          height: 540px;
          box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;

        }
        @include mq(hd){
          height: 630px;
        }
        @include mq(bigger){
        }
        @include mq(full){
          height: 820px;
        }
      }
      &:before{
        @include mq(tablet){
          height: 36px;
        }
        @include mq(bigger){
          height: 56px;
        }
      }
    }
  }
}
