.endless-tale-page-gameplay {
  @include paddingY(50);

  @include mq(tablet){
    @include paddingY(100)
  }
  h2 {
    margin-bottom: 20px;
    @include mq(tablet){
      margin-bottom: 40px;
    }
  }

  &-slider {
    @include marginT(48);
    position: relative;

    @include mq(tablet){
      @include marginT(100);
    }

    .swiper-slide {
      max-width: 1200px;

      img {
        max-width: 100%;
        display: block;
      }
    }

    //background: linear-gradient(270deg, #0A0F19 27.4%, rgba(10, 15, 25, 0.70) 31.18%, rgba(10, 15, 25, 0.00) 35.2%,  rgba(10, 15, 25, 0.00) 62.61%, rgba(10, 15, 25, 0.70) 68.76%, #0A0F19 72.77%);
    &:before {
      content: '';
      position: absolute;
      display: none;
      top: 0;
      bottom: 0;
      left: 0;
      width: 150px;
      background: linear-gradient(90deg, #0A0F19 0%, rgba(10, 15, 25, 0.00) 100%);
      z-index: 5;
      @include mq(tablet){
        display: block;
      }
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 0;
      bottom: 0;
      right: 0;
      width: 150px;
      background: linear-gradient(270deg, #0A0F19 0%, rgba(10, 15, 25, 0.00) 100%);
      z-index: 5;

      @include mq(tablet){
        display: block;
      }
    }

  }

  .gameplay-swiper-navigation {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    @include marginT(8);

    @include mq(tablet){
      @include marginT(40);
    }
    .swiper-button-next, .swiper-button-prev, .swiper-pagination {
      position: unset;
    }

    .swiper-button-next, .swiper-button-prev {
      margin: unset;
      width: unset;
    }

    .swiper-pagination {
      width: 78px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 20px;
      border-left: 1px solid var(--Stroke-Gold-Horizontal, rgba(138, 131, 118, 1));
      border-right: 1px solid var(--Stroke-Gold-Horizontal, rgba(138, 131, 118, 1));
      background: var(--white-3, rgba(255, 255, 255, 0.03));
      //backdrop-filter: blur(25px);

      &-bullet {
        background: url("asset/image/endlessTale/swiper-dot.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 10px;
        height: 10px;
        opacity: unset;
        margin: unset;
        transition: all .2s ease-in;

        &-active {
          background: url("asset/image/endlessTale/swiper-dot-active.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

    }

    .swiper-button-prev {
      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url("asset/image/endlessTale/swiper-arrow-prev.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
      }
    }

    .swiper-button-next {

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url("asset/image/endlessTale/swiper-arrow-prev.svg");
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(180deg);
        background-size: 100% 100%;
      }
    }

  }
}
