@function find_column_width($columns: $column-count) {
  $column-width: calc(100% / $columns);

  @return $column-width;
}
@mixin grid($nested: false, $gutters: true) {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  @if $nested {
    flex: 0 1 auto;
  }

  @if $gutters {
    > * {
      padding-left: $gutter-size;
      padding-right: $gutter-size;
      box-sizing: border-box;
    }
  }
}

// Create a column of the grid
// -----------------------------------------------------------------------------
@mixin column($columns: 0, $md: null, $lg: null) {
  $width: find_column_width();
  $flex-basis: $width * $columns;

  // Setting $columns = 0 will set the column to be auto width and fill available space
  @if ($columns == 0) {
    flex: 1 0 0;
    width: auto;
  } @else {
    flex: 0 0 $flex-basis;
    min-width: $flex-basis;
  }

  // Set the column width on medium screens
  @if ($md != null) {
    @media screen and (min-width: $medium) {
      $flex-basis: $width * $md;
      @if ($md == 0) {
        flex: 1 0 0;
        width: auto;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }

  // Set the column width on large screens
  @if ($lg != null) {
    @media screen and (min-width: $large) {
      $flex-basis: $width * $lg;
      @if ($lg == 0) {
        flex: 1 0 0;
        width: auto;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }
}

// Create a column for a specific breakpoint
// Never needed if purely using grid as mixins
// -----------------------------------------------------------------------------
@mixin mq-column($breakpoint: 'md', $columns: 0) {
  $width: find_column_width();
  $flex-basis: $width * $columns;

  @if ($breakpoint == 'lg') {
    @media screen and (min-width: $large) {
      @if ($columns == 0) {
        flex: 1 0 0;
        width: auto;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  } @else {
    @media screen and (min-width: $medium) {
      @if ($columns == 0) {
        flex: 1 0 0;
        width: auto;
      } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
      }
    }
  }
}

.box {
  background-color: #2196f3;
  color: #fff;
  height: 50px;
  text-align: center;
}

.row {
  @include grid();
}

.column {
  @include column();
}

// Make some base columns
@for $i from 1 through 12 {
  .sm-col-#{$i} {
    @include column($i);
  }
}

// Make some base columns for medium
@for $i from 1 through 12 {
  .md-col-#{$i} {
    @include mq-column('md', $i);
  }
}

// Make some base columns for large
@for $i from 1 through 12 {
  .lg-col-#{$i} {
    @include mq-column('lg', $i);
  }
}

// Make paddings
@function pxToRem($pxSize) {
  @return calc($pxSize / 16) + rem;
}
@mixin paragraphGen() {
  $list: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48 49 50;

  @each $value in $list {
    .pt-#{$value} {
      padding-top: pxToRem($value);
    }
    .px-#{$value} {
        padding-left: pxToRem($value);
        padding-right: pxToRem($value);
    }
    .py-#{$value} {
        padding-top: pxToRem($value);
        padding-bottom: pxToRem($value);
    }
    .pl-#{$value} {
        padding-left: pxToRem($value);
    }
    .pr-#{$value} {
        padding-right: pxToRem($value);
    }
    .pb-#{$value} {
        padding-bottom: pxToRem($value);
    }
    .p-#{$value} {
        padding: pxToRem($value);
    }
    .m-#{$value} {
        margin: pxToRem($value);
    }
    .mt-#{$value} {
        margin-top: pxToRem($value);
    }
    .mx-#{$value} {
        margin-left: pxToRem($value);
        margin-right: pxToRem($value);
    }
    .my-#{$value} {
        margin-top: pxToRem($value);
        margin-bottom: pxToRem($value);
    }
    .ml-#{$value} {
        margin-left: pxToRem($value);
    }
    .mr-#{$value} {
        margin-right: pxToRem($value);
    }
  }
}
@include paragraphGen();
