.invaders-page{
  overflow-x:hidden;
  background: linear-gradient(180deg, #031121 0%, #051A33 24.65%, #04172C 53.65%, #031122 87.7%);

  &-wrapper{
    font-family: 'Montserrat', sans-serif !important;
  }
  section.project{
    min-height: 470px;
    @include mq(mobile){
      min-height: 650px;
    }
    @include mq(medium) {
      min-height: 795px;
    }
    @include mq(tablet) {
      min-height: 993px;
    }
    @include mq(hd) {
      min-height: 1144px;
    }
    @include mq(full) {
      min-height: 1322px;
    }
    @include mq(bigger){
      min-height: 1472px;
    }
    background-color: $white;

    .project-bg{

      &:after{
        background-color: #1FB2FB;
        height: 210px;
        box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 20px 0px 0px #1F1F1F;
        @include mq(mobile){
          height: 290px;
        }
        @include mq(medium){
          height: 350px;
        }
        @include mq(tablet){
          height: 540px;
          box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;

        }
        @include mq(hd){
          height: 630px;
        }
        @include mq(bigger){
          //box-shadow: 0px -64px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;
        }
        @include mq(full){
          height: 820px;
        }


      }
      &:before{
        @include mq(tablet){
          height: 36px;
        }
        @include mq(bigger){
          height: 56px;
        }
      }
    }

    .container{
      display: flex;
      justify-content: center;
    }

  }
  .contact-form{
    .btn-primary{
      background-color: #1FB2FB;
      &:hover{
        border-color: #1FB2FB;
        background-color: #FFFFFF;
        color: #1FB2FB;
      }
      &:active{
        border-color: #FFFFFF;
        background-color: #1FB2FB;
        color: #FFFFFF;
      }
    }
  }
  .footer{
    background-color: #1FB2FB;
  }
}
