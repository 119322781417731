*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;

}

body {

  font: 16px IBM Plex Mono, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial,
  Helvetica, Geneva, sans-serif;
  background-color: hsl(0, 0%, 95%);

  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  // style your wrapper
  margin: 0 auto;
  position: relative;
  //overflow-x: hidden;

  // padding: 2rem 1rem;
}

h1, h2 {
  text-transform: uppercase;
  font-family: 'Geologica-ExtraBold';
}

h1 {
  color: $black;
  text-align: center;
  @include get-font(38);
  line-height: 100%;
  @include mq(small) {
    letter-spacing: -1.52px;
  }
  @include mq(medium) {
    @include get-font(84);
    letter-spacing: -3.36px;
  }
  @include mq(tablet) {
    @include get-font(112);
    letter-spacing: -4.48px;
  }
  @include mq(hd) {
    @include get-font(148);
    letter-spacing: -5.92px;
  }
  @include mq(full) {
    @include get-font(176);
    letter-spacing: -7.04px;
    text-transform: uppercase;
    margin-bottom: 84px;
  }
}

h2 {
  line-height: 100%; /* 94.737% */
  margin: 0;
  color: $black;

  @include mq(small) {
    @include get-font(34);
    margin-top: 10px;
    letter-spacing: -0.68px;
  }
  @include mq(medium) {
    @include get-font(64);
    margin-top: 14px;
    letter-spacing: -1.28px;
  }
  @include mq(tablet) {
    @include get-font(88);
    margin-top: 18px;
    letter-spacing: -1.76px;
  }
  @include mq(hd) {
    @include get-font(100);
    margin-top: 20px;
    letter-spacing: -2px;
  }
  @include mq(full) {
    @include get-font(128);
    margin-top: 26px;
    letter-spacing: -2.56px;
  }
}

p, .content {
  @include mq(small) {
    @include get-font(14);
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.42px;
  }
  @include mq(medium) {
    @include get-font(18);
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.54px;
  }
  @include mq(tablet) {
    @include get-font(18);
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.54px;
  }
  @include mq(hd) {
    @include get-font(28);
    line-height: 140%; /* 39.2px */
    letter-spacing: -0.84px;
  }
  @include mq(full) {
    @include get-font(28);
    line-height: 140%; /* 39.2px */
    letter-spacing: -0.84px;
  }
}

.content {
  font-weight: 400;
}

p {
  color: $black;
}

h3 {
  color: $black;
  @include get-font(12);
  @include mq(medium) {
    @include get-font(16);
  }
  @include mq(hd) {
    @include get-font(24);
  }

}

h4 {
  color: $black;
  font-family: Geologica-ExtraBold, sans-serif;
  font-weight: 700;
  @include mq(small) {
    @include get-font(16);
    line-height: 125%;
    letter-spacing: -0.16px;
  }
  @include mq(medium) {
    @include get-font(24);
    line-height: 116%;
    letter-spacing: -0.24px;
  }
  @include mq(tablet) {
    @include get-font(28);
    line-height: 114%;
    letter-spacing: -0.28px;
  }
  @include mq(hd) {
    @include get-font(32);
    line-height: 125%;
    letter-spacing: -0.32px;
  }
}

.btn-primary {
  display: inline-block;
  background-color: $accent;
  color: $white;
  cursor: pointer;
  font-weight: 400;
  font-family: 'Geologica', sans-serif;
  letter-spacing: -.48px;
  @include get-font(16);
  text-transform: uppercase;
  @include paddingY(16);
  @include paddingX(26);
  border-radius: 999px;
  border: 4px solid $black;

  @include mq(medium) {
    border: 4px solid $black;
    @include get-font(20);
  }

  @include mq(hd) {
    @include get-font(24);
    @include paddingY(20);
    @include paddingX(42);
  }

  @include mq(hd) {
    border: 6px solid $black;
  }

  &:hover {
    color: $black;
  }

  &:active {
    background: $white;
  }
}

a {
  text-decoration: none;
  color: unset;
}

.overscroll-block {
  position: relative;
}

.cursor {

  &-container {
    position: fixed;
    z-index: 1000;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
    pointer-events: none;
  }
  &-inner{
    display: flex;
    &.hidden{
      display: none;
    }
  }
  &-block{
    transform: translate3d(-50%,-50%, 0);
    width: 228px;
    height: 88px;
    border-radius: 999px;
    border: 6px solid $black;
    background: $accent;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    opacity: 1;

    &__arrows{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 24px;
      overflow: hidden;
      justify-content: flex-end;

      &.is__left{
        img{
          transform: translateX(100%);
        }
      }

      img{
        transition: transform 0.45s cubic-bezier(.19, 1, .22, 1);

      }
    }

    &__text {
      height: 24px;
      overflow: hidden;

      p {
        margin: unset;
        font-family: 'Geologica-Bold', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        @include get-font(24);
        line-height: 24px;
        transition: transform 0.45s cubic-bezier(.19, 1, .22, 1);

      }
    }

    &.active{
      .cursor-block__text{
        p{
          transform: translateY(-100%);
        }
      }
      .cursor-block__arrows{
        &.is__right{
          img{
            transform: translateX(100%);
          }
        }
        &.is__left{
          img{
            transform: translateX(0);
          }
        }

      }
    }
  }
}

.info-page{
  @include paddingY(40);
  text-align: justify;
  h3{
    @include get-font(18);

    @include mq(hd) {
      @include get-font(24);
    }
  }
  h4{
    @include get-font(20);

    @include mq(medium) {
      @include get-font(24);
      line-height: 116%;
      letter-spacing: -0.24px;
    }
    @include mq(tablet) {
      @include get-font(28);
      line-height: 114%;
      letter-spacing: -0.28px;
    }
    @include mq(hd) {
      @include get-font(32);
      line-height: 125%;
      letter-spacing: -0.32px;
    }
  }
  p, li{
    font-family: 'Montserrat', sans-serif;
    @include get-font(16);
    @include mq(tablet){
      @include get-font(20);

    }
  }
}
