footer{
  position: relative;
  z-index: 3;
  background-color: $black;
  @include paddingB(20);

  .footer{
    background-color: $accent;

    &-wrapper{
      @include flexbox($direction: row, $alignItems: flex-start, $wrap: wrap);
      @include paddingY(48);

      color: $white;
      gap: 1.5rem;

      @include mq(medium){
        gap: 2rem;
      }
      @include mq(tablet){
        @include paddingY(64);

      }

      @include mq(hd){
        gap: 4rem;
      }

      &-item{
        //width: 150px;
        @include flexbox($direction: column);
        flex-grow: 1;
        width: 100%;
        @include mq(medium){
          width: 45%;
        }
        @include mq(tablet){
          width: unset;
          flex-basis: 0;

          //max-width: 200px;
        }
        h4{
          font-family: Geologica, sans-serif;
          font-weight: 500;
          margin: unset;
          @include marginB(12);
          color: $white;
        }
        &-content{
          @include flexbox($direction: row);
          gap: 20px;
          @include mq(medium){
            gap: unset;
            flex-direction: column;
          }
        }
        a{
          color: $white;
          @include get-font(14);
          line-height: 200%;
          letter-spacing: -0.54px;
          @include mq(mobile){
            @include get-font(18);
          }
          &:hover{
            text-decoration: underline;
            text-underline-offset: 5px;
            text-decoration-color: $white;
          }
          &:active{
            color: hsla(0, 0%, 100%, 0.75);
            text-decoration-color: hsla(0, 0%, 100%, 0.75);

          }
        }
      }
    }
  }
}
