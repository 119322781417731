.endless-tale-page-heroes{
  @include paddingY(50);

  @include mq(tablet){
    @include paddingY(100);
  }
  position: relative;
  background: url("asset/image/endlessTale/heroes-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &-arrow{
    position: absolute;
    max-width: 148px;

    @include mq(tablet){
      max-width: unset;
    }

    img{
      max-width: 100%;
    }
    &.left{
      left: 0;
      top: 60%;
      transform: translateY(-50%);
    }
    &.right{
      right: 0;
      top: 60%;
      transform: translateY(-50%);
    }
  }
  &-wrapper{
    h2{
      @include marginB(20);

      @include mq(tablet){
        @include marginB(40);
      }
    }
    &-items{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 12px;
      @include marginT(48);
      flex-wrap: wrap;
      @include mq(tablet){
        @include marginT(100);
        gap: 20px;
        flex-wrap: nowrap;


      }
      .item{
        max-width: 264px;
        width: 40%;
        @include mq(tablet){
          width: 25%;

        }
        img{
          max-width: 100%;
        }
      }
    }
  }
}
