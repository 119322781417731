@function pxToRem($pxSize) {
    @return calc($pxSize / 16) + rem;
  }
@mixin font-size() {

    $font-size-list: 14 18 16 20 24 28 32 40 100 112 128 148 176;
    $h1-list-font: 38 84 112 148 176;

    $desktops: 0 768 1024 1366 1920;

    $points: 'zero' 'medium' 'tablet' 'hd' 'full';
    
    .h1-0 {
        // font-weight: 800;
        font-family: 'Geologica';
        text-transform: uppercase;
        @for $i from 1 to length($h1-list-font) {
            @media (min-width: nth($desktops,  $i)) {
                font-size: pxToRem(nth($h1-list-font,  $i));   
            }
        }
    }
  
    @each $value in $font-size-list {
      .font-size-#{$value} {
        font-size: pxToRem($value);
      }
    }

  }

@mixin get-font($value) {
    font-size: pxToRem($value);
}

@mixin paddingX($value) {
    padding-left: pxToRem($value);
    padding-right: pxToRem($value);
}

@mixin paddingY($value) {
    padding-top: pxToRem($value);
    padding-bottom: pxToRem($value);
}
@mixin paddingT($value) {
    padding-top: pxToRem($value);
}
@mixin paddingB($value) {
    padding-bottom: pxToRem($value);
}

@mixin marginT($value) {
    margin-top: pxToRem($value);
}
@mixin marginB($value) {
    margin-bottom: pxToRem($value);
}
@mixin marginY($value) {
    margin-top: pxToRem($value);
    margin-bottom: pxToRem($value);
}

@mixin marginX($value) {
    margin-left: pxToRem($value);
    margin-right: pxToRem($value);
}
  
@include font-size();