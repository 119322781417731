.invaders-page-weapons {
  display: flex;
  justify-content: center;
  text-align: center;
  @include paddingT(48);
  @include paddingB(48);
  position: relative;

  @include mq(medium){
    @include paddingT(82);
    @include paddingB(82);
  }

  @include mq(tablet){
    @include paddingB(99);
  }

  @include mq(hd){
    @include paddingT(100);
    @include paddingB(132);
  }

  @include mq(bigger){
    @include paddingB(198);
  }

  &--top-bg {
    transform: translate3d(0, 0, 0);

    position: absolute;
    top: 0;
    //left: 50%;
    //transform: translateX(-50%);
    width: 1500px;
    height: 549px;
    border-radius: 1613px;
    background: linear-gradient(180deg, rgba(255, 66, 100, 0.36) 16.22%, rgba(255, 66, 100, 0.03) 100%);
    filter: blur(125px);
    @include mq(medium) {
      width: 2012px;
      height: 675px;
      border-radius: 2021px;
      filter: blur(250px);

    }
    @include mq(tablet) {
      width: 2277px;
      height: 761px;
      border-radius: 2277px;
    }
    @include mq(hd) {
      width: 2619px;
      height: 876px;
      border-radius: 2619px;
    }
    @include mq(bigger) {
      width: 3173px;
      height: 1062px;
      border-radius: 3173px;
    }

  }

  &-wrapper {
    position: relative;
    z-index: 10;

    &-preface {
      margin: 0 auto;
      @include marginB(128);

      @include mq(medium) {
        @include marginB(181);
      }

      @include mq(tablet) {
        @include marginB(161);
      }

      @include mq(hd) {
        @include marginB(170);
      }
      @include mq(bigger) {
        @include marginB(135);
      }


      h2 {
        @include get-font(24);
        font-weight: 800;
        line-height: 100%;
        letter-spacing: -.48px;
        text-transform: uppercase;
        opacity: 0.5;
        background: linear-gradient(0deg, rgba(255, 66, 100, 0.25) 2.92%, #FF4264 98.99%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include mq(medium) {
          @include get-font(40);
          letter-spacing: -.8px;
        }
        @include mq(tablet) {
          @include get-font(56);
          letter-spacing: -1.12px;
        }
        @include mq(hd) {
          @include get-font(72);
          letter-spacing: -1.44px;
        }
        @include mq(bigger) {
          @include get-font(96);
          letter-spacing: -1.92px;
        }


      }
    }

    &-title {
      position: relative;
      @include flexbox($direction: column, $alignItems: center);
      //gap: 64px;
      @include marginB(48);

      @include mq(medium){
        padding-top: 20px;
        @include marginB(64);
      }
      @include mq(tablet){
        padding-top: 40px;
        @include marginB(80);

      }
      @include mq(hd){
        @include marginB(96);
        padding-top: 70px;
      }
      @include mq(bigger){
        @include marginB(128);
        padding-top: 120px;
      }

      &-bg {
        position: absolute;
        top: 0;
        margin: 0 auto;
        //left: 50%;
        //transform: translateX(-50%);
        opacity: .05;
        max-width: 324px;

        @include mq(medium) {
          max-width: 432px;
        }
        @include mq(tablet) {
          max-width: 576px;
        }
        @include mq(hd) {
          max-width: 768px;
        }
        @include mq(bigger) {
          max-width: 1024px;
        }


        img {
          max-width: 100%;
        }
      }

      &-image {
        z-index: 3;
        max-width: 72px;
        @include marginB(45);

        @include mq(hd) {
          max-width: 96px;
        }
        @include mq(bigger) {
          @include marginB(70);
          max-width: 128px;
        }

        img {
          max-width: 100%;
        }
      }

      h3 {
        color: #FFFFFF;
        @include get-font(24);
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.24px;
        text-transform: uppercase;
        margin: unset;
        @include marginB(34);

        span {
          color: #1FB2FB;
        }

        @include mq(medium) {
          @include get-font(32);
          line-height: 40px;
          letter-spacing: -0.32px;
        }
        @include mq(tablet){
          @include marginB(42);
        }
        @include mq(hd) {
          @include get-font(40);
          line-height: 48px;
          letter-spacing: -0.4px;
        }

        @include mq(bigger) {
          @include get-font(64);
          line-height: 72px;
          letter-spacing: -0.64px;
        }
      }

      p {
        margin: unset;
        color: rgba(255, 255, 255, 0.75);
        font-size: 24px;
        @include get-font(16);
        font-weight: 500;
        line-height: 150%; /* 36px */
        letter-spacing: -0.32px;
        max-width: 1050px;

        @include mq(hd){
          @include get-font(20);
          letter-spacing: -0.4px;
        }
        @include mq(bigger){
          @include get-font(24);
          letter-spacing: -0.48px;
        }

      }
    }

    &-types {
      @include flexbox($direction: row, $justifyContent: center, $alignItems: flex-start, $wrap: wrap);
      position: relative;
      gap: 32px;

      @include mq(medium) {
        gap: 48px 24px;
      }
      @include mq(tablet) {
        gap: 64px 32px;
      }
      @include mq(hd) {
        gap: 112px 182px;
      }
      @include mq(bigger) {
        gap: 94px 176px;
      }
      &-bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 1680px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(27, 150, 211, 0.75) 0%, rgba(27, 150, 211, 0.00) 100%);
        filter: blur(250px);
        z-index: 2;
        transform: translate3d(0, 0, 0);

      }

      &-item {
        z-index: 3;
        @include flexbox($direction: column, $alignItems: center);
        text-align: center;
        background-image: url("asset/image/invaders/text-block-bg.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        flex: 0 1 auto;
        @include paddingB(32);
        @include paddingT(16);
        @include paddingX(28);
        //flex: 0 1 auto;
        max-width: 328px;

        @include mq(medium) {
          max-width: 340px;
          @include paddingX(36);
          @include paddingB(40);
        }
        @include mq(tablet) {
          max-width: 464px;
          @include paddingX(40);
          @include paddingB(48);

        }
        @include mq(hd) {
          max-width: 528px;
          @include paddingX(48);
          @include paddingB(64);
        }

        @include mq(full) {
          @include paddingX(64);
          max-width: 680px;
        }
        @include mq(full) {
          max-width: 680px;
        }

        &:nth-child(2) {
          @include mq(medium){
            @include marginT(48)
          }
          @include mq(tablet){
            @include marginT(64)
          }
          @include mq(bigger){
            @include marginT(116)
          }


        }

        .item-image {
          width: 100%;
          @include marginB(24);

          @include mq(tablet) {
            @include marginB(32);
          }

          img {
            max-width: 100%;
          }
        }

        h6 {
          margin: unset;
          color: $black;
          @include get-font(24);
          font-weight: 700;
          letter-spacing: -.96px;
          z-index: 5;
          @include marginB(16);

          @include mq(medium) {
            @include marginB(24);
            @include get-font(28);
            letter-spacing: -1.12px;
          }

          @include mq(tablet) {
            @include get-font(32);
            letter-spacing: -1.28px;
          }
          @include mq(hd) {
            @include get-font(40);
            letter-spacing: -1.6px;
          }


        }

        p {
          margin: unset;
          color: rgba(31, 31, 31, 0.75);
          @include get-font(14);

          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: -.28px;
          z-index: 5;

          @include mq(hd) {
            @include get-font(16);
            letter-spacing: -.32px;
          }
          @include mq(bigger) {
            @include get-font(20);
            letter-spacing: -.4px;
          }

        }
      }
    }
  }
}
