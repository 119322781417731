.story-scape-page-info{
  @include paddingT(96);
  @include paddingB(48);

  @include mq(tablet){
    @include paddingT(140);
    @include paddingB(140);
  }

  &-wrapper{
    @include flexbox($direction: column);
    gap: 24px;

    @include mq(medium){
      gap: 64px;
    }

    &-video{
      position: relative;
      cursor: pointer;

      .play-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
        border-radius: 16px;
        border: 1px solid var(--gradient-stroke, rgba(138, 131, 118, 0.00));
        background: var(--gradient-blue, linear-gradient(180deg, #4C4BD6 0%, #3332B7 100%));
        box-shadow: 0 10px 124px 0 rgba(72, 71, 209, 0.60);
        cursor: pointer;

        @include flexbox($justifyContent: center, $alignItems:center);
        @include mq(medium){
          width: 140px;
          height: 140px;
          border-radius: 46px;
        }

        img{
          max-width: 24px;
          @include mq(medium){
            max-width: 52px;
          }

        }
      }
      video{
        max-width: 100%;
        border-radius: 35px;
        @include mq(medium) {
          border-radius: 50px;
        }
      }
    }
    p{
      color: $white;
      text-align: center;
      //font-family: Outfit;
      @include get-font(12);
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;
      margin: unset;

      @include mq(medium){
        font-weight: 500;
        @include get-font(28);
        letter-spacing: -.84px;
      }

    }
  }
}
