.story-scape-page-moral{
  @include paddingB(48);

  @include mq(medium){
    @include paddingB(100);
  }
  &-wrapper{
    &-image{
      img{
        max-width: 100%;
        filter: drop-shadow(0px 70px 70px rgba(51, 51, 51, 0.12));
      }
    }
  }
}
