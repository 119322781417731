.endless-tale-page-create{
  @include paddingB(50);
  @include paddingT(50);
  @include mq(tablet){
    @include paddingB(100);
    @include paddingT(100);

  }
  .container{
    max-width: 1568px;
  }
  &-wrapper{

    h2{
      margin-bottom: 20px;
      @include mq(tablet){
        margin-bottom: 40px;
      }
    }

    &-image{
      @include marginT(48);
      @include mq(tablet){
        @include marginT(100);

      }
      img{
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}
