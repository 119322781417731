.invaders-page-info{
  display: flex;
  justify-content: center;
  @include paddingT(48);
  @include paddingB(115);
  position: relative;

  @include mq(medium){
    @include paddingT(64);
    @include paddingB(108);
  }
  @include mq(tablet){
    @include paddingT(82);
    @include paddingB(30);
  }
  @include mq(hd){
    @include paddingT(160);
    @include paddingB(50);
  }

  @include mq(bigger){
    @include paddingB(170);
  }



  &-bg{

    width: 162%;
    position: absolute;
    //left: 50%;
    transform: translate3d(0, 0, 0);

    border-radius: 3121px;
    background: radial-gradient(53.58% 37.02% at 50% 50%, rgba(254, 85, 1, 0.75) 0%, rgba(254, 85, 1, 0.00) 100%);
    filter: blur(270px);
    top: 10%;
    //transform: translate(-50%,-50%);
    height: 70%;

    @include mq(medium){
      //transform: translate(-50%, 0);
      top: -10%;
      height: 100%;
    }
    @include mq(tablet){
      filter: blur(499.5px);

      top: -20%;
      height: 148%;
    }

    @include mq(hd){
      top: 0;
    }

  }

  &-wrapper{
    @include flexbox($direction: column);

    @include mq(hd){
      gap: 82px;
      flex-direction: row;
    }
    @include mq(bigger){
      gap: 100px;
    }

    &-item{
      @include flexbox($direction: column, $alignItems: flex-start);
      flex: 0 1 auto;
      position: relative;

      .text-block{
        background-image: url("asset/image/invaders/text-block-bg.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        filter: drop-shadow(16px 16px 30px rgba(16, 16, 16, 0.25));
        backdrop-filter: blur(2px);

        @include paddingY(32);
        @include paddingX(24);

        @include mq(medium){
          @include paddingX(36);
          max-width: 455px;

        }
        @include mq(tablet){
          max-width: 464px;
        }

        @include mq(hd){
          max-width: 640px;
          @include paddingX(48);
        }

        @include mq(bigger){
          @include paddingX(60);
          @include paddingY(40);
        }

        h4{
          font-family: 'Montserrat', sans-serif !important;
          font-weight: 800;
          @include get-font(20);
          color: $black;
          margin: unset;
          @include marginB(16);
          text-transform: uppercase;

          @include mq(tablet){
            @include get-font(28);
          }
          @include mq(hd){
            @include marginB(20);

            @include get-font(28);
          }
          @include mq(bigger){
            @include get-font(32);
          }

          span{
            color: #1FB2FB;
          }
        }

        p{
          margin: unset;
          color: rgba(31, 31, 31, 0.75);
          @include get-font(14);
          font-weight: 500;
          line-height: 150%; /* 30px */
          letter-spacing: -0.4px;

          @include mq(hd){
            @include get-font(16);
          }

          @include mq(bigger){
            @include get-font(20);
          }
        }
      }

      .gif-block{

        position: absolute;
        width: 250px;
        bottom: -65%;
        left: 50%;
        transform: translateX(-50%);

        @include mq(medium){
          width: unset;
          transform: unset;
          bottom: -150px;
          left: -40px;
          max-width: 300px;
        }

        @include mq(tablet){
          bottom: 110px;
          left: 0;
          max-width: 370px;
        }

        @include mq(hd){
          bottom: -300px;
          left: -145%;
          max-width: 370px;
        }

        @include mq(bigger){
          bottom: -300px;
          left: -125%;
          max-width: 490px;

        }

        img{
          max-width: 100%;
        }
        .turret-image{
          z-index: 4;
          position: relative;
        }
        .shadow-image{

          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          max-width: 170px;
          bottom: 60px;

          @include mq(medium){
            max-width: 225px;
            bottom: 70px;
          }

          @include mq(tablet){
            max-width: 270px;
            bottom: 80px;
          }

          @include mq(hd){
            bottom: 90px;
          }

          @include mq(bigger){
            max-width: 100%;
            bottom: 120px;
          }

        }
      }

      .phone-block{
        position: relative;
        width: 234px;
        filter: drop-shadow(16px 16px 30px rgba(16, 16, 16, 0.25));
        background-image: url("asset/image/invaders/iPhone-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
        padding: 9px 10px;
        align-self: center;
        @include marginB(48);
        video{
          max-width: 100%;
          display: block;
          border-radius: 16px;
          @include mq(bigger){
            border-radius: 31px;

          }
        }

        @include mq(medium){
          @include marginB(58);
        }
        @include mq(bigger){
          width: 312px;
          padding-top: 13px;
          padding-left: 12px;
          padding-right: 12px;
          padding-bottom: 12px;
          margin-bottom: unset;
        }

      }

      &:nth-child(1){

        .text-block{
          @include marginB(76);

          @include mq(medium){
            @include marginB(114);
          }
          @include mq(tablet){
            @include marginB(46);
          }

          @include mq(hd){
            @include marginT(130);
            margin-bottom: unset;
          }

        }
      }
      &:nth-child(3){
        .text-block{
          @include mq(medium){
            align-self: flex-end;
          }
          @include mq(hd){
            margin-top: auto;
            margin-bottom: -185px;
          }

          @include mq(bigger){
            margin-bottom: -145px;
          }


        }
      }

      .flyer-block{
        position: absolute;
        top: -70px;
        right: -70px;
        max-width: 160px;

        @include mq(medium){
          top: -90px;
          right: -80px;
          max-width: 172px;
        }
        @include mq(bigger){
          top: -100px;
          right: -100px;
          max-width: 212px;
        }

        img{
          max-width: 100%;
        }
      }
    }
  }
}
