.amplifier-info{
  background: linear-gradient(180deg, #3485F7 0%, #2660B2 100%);
  @include paddingY(96);

  @include mq(medium){
    @include paddingY(120);
  }

  @include mq(tablet){
    @include paddingY(160);
  }

  @include mq(hd){
    @include paddingY(200);
  }

  &-wrapper{
    @include flexbox($direction: column);
    gap: 48px;

    @include mq(medium){
      gap: 80px;
    }
    @include mq(tablet){
      gap: 120px;
    }

    &-technology{
      @include flexbox($direction: column, $justifyContent: flex-start, $alignItems: flex-start);
      gap: 24px;

      @include mq(tablet){
        flex-direction: row;
        align-items: center;
      }

      @include mq(hd){
        gap: 40px;
      }

      p{
        flex-grow: 1;
        margin: unset;
        color: $white;
        font-family: 'Montserrat', sans-serif;
        @include get-font(16);
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.32px;

        @include mq(medium){
          @include get-font(24);
          letter-spacing: -0.48px;
        }
        @include mq(hd){
          @include get-font(32);
          letter-spacing: -0.64px;
        }

      }

    }

    &-product{

      p{
        margin: unset;
        color: $white;
        font-family: 'Montserrat', sans-serif;
        @include get-font(32);
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.96px;

        @include mq(medium){
          @include get-font(40);
          letter-spacing: -1.2px;
        }
        @include mq(tablet){
          @include get-font(56);
          letter-spacing: -1.68px;
        }
        @include mq(hd){
          @include get-font(64);
          letter-spacing: -1.92px;
        }


        span{

          &:first-child{
            font-weight: 500;
          }

          &:nth-child(2){
            color: #3485F7;
            background-color: $white;
            @include paddingX(8)
          }
        }
      }
    }
    &-approach{
      background: $white;
      padding: 16px;
      @include mq(medium){
        padding: 24px;
      }
      @include mq(tablet){
        padding: 32px;
        order: 4;
      }

      p{
        margin: unset;
        color:#285391;
        font-family: 'Montserrat', sans-serif;
        @include get-font(16);
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.32px;
        span{
          color: #3485F7;
        }

        @include mq(medium){
          @include get-font(24);
          letter-spacing: -0.48px;
        }
        @include mq(hd){
          @include get-font(32);
          letter-spacing: -0.64px;
        }

      }
    }
    &-benefits{
      @include flexbox($wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
      gap: 24px;

      @include mq(hd){
        gap: 40px;
      }
    }
  }
}
