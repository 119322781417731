@import url('https://fonts.googleapis.com/css2?family=Texturina:wght@400;700&family=IBM+Plex+Mono:wght@300;400;600;700&family=Lexend:wght@700;900&family=Montserrat:wght@400;500;600;700;800&family=Geologica:wght@900&family=Outfit:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'SF Mono Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Regular'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff')
      format('woff');
}
@font-face {
  font-family: 'SF Mono Regular';
  font-style: normal;
  font-weight: 600;
  src: local('SF Mono Regular'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoBold.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Regular'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Regular Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoRegularItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Light';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Light'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoLight.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Light Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoLightItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Medium'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoMedium.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Medium Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoMediumItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Semibold'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoSemibold.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Semibold Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoSemiboldItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Bold'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoBold.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Bold Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoBoldItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Heavy'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoHeavy.woff')
      format('woff');
}

@font-face {
  font-family: 'SF Mono Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('SF Mono Heavy Italic'),
    url('./asset/font/sf-mono-cufonfonts-webfont/SFMonoHeavyItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'Geologica Auto Black';
  font-style: normal;
  font-weight: 900;
  src: local('Geologica Auto Black'),
    url('./asset/font/Geologica/static/Geologica_Auto-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Geologica Auto Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Geologica Auto Bold'),
    url('./asset/font/Geologica/static/Geologica_Auto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Geologica Black';
  font-style: normal;
  font-weight: 900;
  src: local('Geologica Black'),
    url('./asset/font/Geologica/static/Geologica-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Geologica-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Geologica-Bold'),
    url('./asset/font/Geologica/static/Geologica-Bold.ttf') format('ttf'),
    url('./asset/font/Geologica/static/Geologica-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Geologica-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica-ExtraBold.ttf') format('ttf'),
    url('./asset/font/Geologica/static/Geologica-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 800;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica-ExtraBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 800;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica_Auto-SemiBold.ttf') format('ttf'),
		url('./asset/font/Geologica/static/Geologica_Auto-SemiBold.woff') format('woff')
}

@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  src: local('Geologica Medium'),
    url('./asset/font/Geologica/static/Geologica-Medium.ttf') format('true-type'),
    url('./asset/font/Geologica/static/Geologica_Auto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 700;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica-SemiBold.ttf') format('ttf'),
    url('./asset/font/Geologica/static/Geologica-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  src: local('Geologica ExtraBold'),
    url('./asset/font/Geologica/static/Geologica-Regular.ttf') format('truetype'),
    url('./asset/font/Geologica/static/Geologica_Auto-Regular.ttf') format('truetype');
}

