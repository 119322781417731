.welcome{
  overflow-x: hidden;
  @include paddingT(64);
  @include mq(medium){
    @include paddingT(86);
  }

  @include mq(tablet){
    @include paddingT(112);
  }
  .pin-spacer{
    //height: 100% !important;
  }
  .pinner-mobile-frame{
    height: auto !important;
    //padding-bottom: 200px;
  }
}

//animations for hero title

//first animation
@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
    transform: translateZ(-800px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

//second animation
@keyframes expand-letter-o {
  20% {
    width: 32px;
  }
  40% {
    width: 46px;
  }
  60% {
    width: 46px;
  }
  80% {
    width: 32px;
  }

}

@include mq(medium) {
  @keyframes expand-letter-o {
    20% {
      width: 64px;
    }
    40% {
      width: 100px;
    }
    60% {
      width: 100px;
    }
    80% {
      width: 64px;
    }

  }
}

@include mq(tablet) {
  @keyframes expand-letter-o {
    20% {
      width: 86px;
    }
    40% {
      width: 144px;
    }
    60% {
      width: 144px;
    }
    80% {
      width: 86px;
    }

  }
}

@include mq(hd) {
  @keyframes expand-letter-o {
    20% {
      width: 112px;
    }
    40% {
      width: 163px;
    }

    60% {
      width: 163px;
    }
    80% {
      width: 112px;
    }

  }
}

@include mq(full) {
  @keyframes expand-letter-o {
    20% {
      width: 135px;
    }

    40% {
      width: 239px;
    }

    60% {
      width: 239px;
    }

    80% {
      width: 135px;
    }
  }
}

//third animation
@keyframes AR_AI-block-animation {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

//fourth animation
@keyframes AR_AI-text-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//fifth animation
@keyframes AR_AI-block-shape-animation {
  0% {
    //border-width: 0;
    border-color: transparent;
    border-radius: 0;
  }
  100% {
    //border-width: 12px;
    border-color: $black;
    border-radius: 999px;
  }
}

//sixth animation
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

h1 {
  margin: unset;
  @include marginT(48);
  @include marginB(36);
  @include mq(mobile){
    @include marginB(64);
  }
  @include mq(medium) {
    @include marginT(64);
    @include marginB(86);

  }
  @include mq(tablet) {
    @include marginT(86);
  }
  @include mq(hd) {
    @include marginB(100);
  }


  div {
    &:nth-child(1), &:nth-child(3) {
      display: inline-block;
      animation: focus-in-expand-fwd .8s ease-in-out both;
    }

    &:nth-child(5) {
      display: inline-block;
      animation: slide-in-right 0.5s ease-in-out both;
      animation-delay: 2.3s;
    }
  }

  span.letter-o {
    display: inline-block;
    width: 30px;
    color: $black;
    height: 29px;
    border: 7px solid $black;
    border-radius: 999px;
    animation: expand-letter-o infinite 3s cubic-bezier(.47,1.64,.41,.8);
    animation-delay: .8s;
    margin-left: 2px;
    @include mq(medium) {
      height: 64px;
      width: 64px;
      border-width: 15px;
      margin-left: 3px;
      margin-right: -2px;

    }
    @include mq(tablet) {
      height: 86px;
      width: 86px;
      border-width: 20px;
      margin-left: 4px;

    }
    @include mq(hd) {
      height: 112px;
      width: 112px;
      border-width: 26px;
      margin-left: 5px;

    }
    @include mq(full) {
      height: 135px;
      width: 135px;
      border-width: 29px;
      margin-left: 7px;
      margin-right: -3px;
    }


  }

  span.ar-ai {
    background: $accent;
    display: inline-block;
    border-color: transparent;
    border-style: solid;
    border-width: 3.104px;
    //border-radius: 999px;
    //border: 3.104px solid $black;
    margin-left: 12px;
    @include paddingX(13);
    @include paddingY(8);
    animation: AR_AI-block-animation .5s ease-in-out both,
    AR_AI-block-shape-animation 3s ease-in-out both;
    animation-delay: .8s, 2.8s;

    p {
      display: inline;
      font-size: unset;
      font-family: IBM Plex Mono, sans-serif;
      font-weight: 300;
      color: $white;
      animation: AR_AI-text-fade-in 1s ease-in-out both;
      animation-delay: 1.2s;
      line-height: unset;
    }

    @include mq(medium) {
      border-radius: 666px;
      border: 7.538px solid $black;
      @include paddingX(23);
      margin-left: 24px;
    }
    @include mq(tablet) {
      border-radius: 832.5px;
      border: 9.423px solid $black;
      @include paddingX(40);
      margin-left: 32px;
    }
    @include mq(hd) {
      border-radius: 999px;
      border: 11.308px solid $black;
      @include paddingX(36);
    }
    @include mq(full) {
      border-radius: 1006px;
      border: 12px solid $black;
      @include paddingX(52);
    }
  }
}

// animation for mobile frame wrapper
@keyframes mobile-animation {
  0% {
    height: 320px;
  }
  100% {
    height: 160px;
  }

}

@include mq(medium) {
  @keyframes mobile-animation {
    0% {
      height: 528px;
    }
    100% {
      height: 264px;
    }
  }
}

@include mq(tablet) {
  @keyframes mobile-animation {
    0% {
      height: 720px;
    }
    100% {
      height: 360px;
    }
  }
}

@include mq(hd) {
  @keyframes mobile-animation {
    0% {
      height: 980px;
    }
    100% {
      height: 590px;
    }

  }
}

//animation for background image radius

@keyframes radius-animation {
  from {
    border-radius: 0;
  }
  to {
    border-radius: 20px;
  }
}

@include mq(medium){
  @keyframes radius-animation {
    from {
      border-radius: 0;
    }
    to {
      border-radius: 30px;
    }
  }
}

@include mq(hd) {
  @keyframes radius-animation {
    from {
      border-radius: 0;
    }
    to {
      border-radius: 40px;
    }
  }
}

.mobileFrameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%!important;
  width: 100%!important;
  // test animation
  height: calc(100vh - .5px);

  //@include marginB(96);
  @include mq(mobile) {
    //@include marginB(128);
  }
  @include mq(medium) {
    @include marginB(164);
    height: 100vh;
    //height: 520px;
  }
  @include mq(tablet){
    height: 100vh;

  }
  @include mq(hd) {
    //@include marginB(200);
    margin: unset;
    height: calc(100vh - .5px);
  }

}

.animation-section {

  position: relative;
  //position: absolute;
  text-align: center;
  width: 100%;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  height: 320px;
  height: 100%;

  //animation: mobile-animation 1s ease-in both;
  //animation-play-state: paused;
  ///* Bind the animation to scroll */
  //animation-delay: calc(var(--scroll) * -1s);
  ///* These last 2 properites clean up overshoot weirdness */
  //animation-iteration-count: 1;
  @include mq(medium) {
    height: 528px;
    height: 100%;

  }
  @include mq(tablet){
    height: 720px;
    height: 100%;

  }
  @include mq(hd) {
    height: 980px;
    height: 100%;
  }

  img {
    position: absolute;
  }

  &-frame {
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 3;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.4));
    top: -5px;
    @include mq(tablet){
      top: -10px;
    }
    @include mq(hd) {
      top: -25px;
    }
  }

  &-bg {
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    //border-radius: 0;
    height: 100%;

    @include mq(mobile) {
      //left: 0;
      //transform: unset;
    }

    @include mq(medium){
      left: 50%;
      transform: translateX(-50%);
      height: 100%;

    }

    @include mq(hd) {
      height: 100%;
    }
  }

  &-elements {
    margin: 0 auto;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 2;
    max-height: 93%;
    max-width: 100%;
    opacity: 0;
    transform: translateZ(50px);
    @include mq(hd) {
      max-height: 100%;
      top: 0;
    }
  }
}
