.invaders-page-christmas {
  position: relative;
  @include paddingT(152);
  @include paddingB(100);

  @include mq(medium){
    @include paddingT(244);
    @include paddingB(206);
  }
  @include mq(tablet){
    @include paddingT(272);
    @include paddingB(227);
  }
  @include mq(hd){
    @include paddingT(226);
    @include paddingB(370);
  }
  @include mq(full){
    @include paddingT(173);
    @include paddingB(440);
  }

  .display-none {
    display: none;
  }

  &-bg {
    position: absolute;
    border-radius: 3121px;
    background: radial-gradient(53.58% 37.02% at 50% 50%, #2B5E57 0%, rgba(43, 94, 87, 0.35) 99.48%);
    transform: translate3d(0, 0, 0);
    filter: blur(250px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-wrapper {
    background-color: $white;
    position: relative;
    @include paddingB(105);

    @include mq(medium) {
      @include paddingB(20);
    }
    @include mq(tablet) {
      @include paddingB(60);
    }

    &-lines {
      position: absolute;
      left: -45px;
      top: 0;
      bottom: 0;
      z-index: 3;
      @include mq(medium) {
        left: -125px;
      }
      @include mq(tablet) {
        left: -125px;
      }
      @include mq(tablet) {
        left: -155px;
      }
      @include mq(fullHD) {
        left: 0;
      }


      img {
        max-width: 100%;
        max-height: 30%;
        @include mq(medium) {
          max-height: 80%;
        }
        @include mq(tablet) {
          max-height: 100%;
        }

      }
    }

    &-ice {
      position: absolute;
      top: 95%;
      left: -20px;
      right: -20px;
      @include mq(mobile) {
        top: 90%;
      }

      @include mq(medium) {

      }
      @media (min-width: 858px) {
        top: 85%;

      }
      @include mq(hd) {
        top: 78%;
      }

      img {
        width: 100%;
      }
    }

    .snowman {
      position: absolute;
      bottom: 0;
      left: -20px;
      width: 157px;
      z-index: 5;
      @include mq(medium) {
        width: 210px;
        left: 0;
        bottom: 20px;
      }

      @include mq(hd) {
        width: 280px;
        left: 70px;
      }
      @include mq(full) {
        width: 373px;
      }
      @include mq(fullHD) {
        left: 200px
      }


      img {
        max-width: 100%;
      }
    }

    .cookie {
      position: absolute;
      right: -20px;

      width: 126px;
      bottom: 0;
      @include mq(medium) {
        width: 168px;
        bottom: unset;
        top: 50%;
        transform: translateY(-25%);
        right: 0;
      }
      @include mq(hd) {
        width: 224px;
      }
      @include mq(full) {
        width: 298px;
      }

      img {
        max-width: 100%;
      }
    }

    &-content {
      position: relative;
      z-index: 5;
      @include flexbox($direction: column, $alignItems: center, $justifyContent: center);


      .logo {
        max-width: 297px;
        @include marginB(32);
        @include marginT(-60);

        img {
          width: 100%;
        }

        @include mq(medium) {
          max-width: 396px;
          @include marginT(-80);
        }
        @include mq(tablet) {
          max-width: 460px;
          @include marginB(36);
          @include marginT(-100);

        }
        @include mq(hd) {
          max-width: 577px;
          @include marginB(44);
          @include marginT(-120);

        }
      }

      h2 {
        margin: unset;
        text-align: center;
        color: #1F1F1F;
        font-family: 'Geologica', sans-serif;
        @include get-font(48);
        font-weight: 900;
        line-height: 100%;
        letter-spacing: -0.96px;
        text-transform: uppercase;
        @include marginB(24);

        span {
          color: #BF4A39;
        }

        @include mq(medium) {
          @include marginB(28);

        }
        @include mq(tablet) {
          @include get-font(64);
          letter-spacing: -1.28px;
          @include marginB(36);

        }
        @include mq(hd) {
          @include get-font(86);
          letter-spacing: -1.72px;
        }
        @include mq(full) {
          @include get-font(100);
          letter-spacing: -2px;
        }


      }

      .links {
        @include flexbox($alignItems: center, $justifyContent: center, $wrap: wrap);
        gap: 16px 12px;
        max-width: 100%;

        @include mq(medium) {
          gap: 24px 15px;
          max-width: 80%;
        }
        @include mq(tablet) {
          gap: 27px;
          max-width: 100%;
        }
        @include mq(hd) {
          gap: 42px;
        }

        &-item {
          max-width: 150px;

          img {
            max-width: 100%;
            display: block;
          }

          &.google-play {
            max-width: 152px;
            @include mq(medium) {
              max-width: 210px;
            }
          }

          &.app-store {
            max-width: 140px;
            @include mq(medium) {
              max-width: 191px;
            }
          }

          &.discord {
            max-width: 148px;
            @include mq(medium) {
              max-width: 204px;
            }
          }

        }
      }
    }
  }
}
