.our-team {
  overflow: hidden;

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  .heading-block{
    h2{
      br{
        @include mq(hd){
          display: none;
        }
      }
    }
  }
  .content {
    &.display-desktop {
      display: none;
      @include mq(medium) {
        display: block;
      }
    }

    &.display-mobile {
      display: block;

      @include mq(medium) {
        display: none;
      }
    }
  }

  .team {
    overflow: hidden;
    padding-top: 20px;
    @include marginT(120);
    @include marginB(120);


    @include mq(mobile) {
      @include flexbox($wrap: wrap, $justifyContent: center, $alignItems: flex-start);
      gap: 24px;

    }
    @include mq(tablet) {
      gap: 32px;
      @include marginB(185);

    }
    @include mq(hd){
      @include marginB(200);

    }
    @include mq(full) {
      gap: 54px;
    }

    &-member {
      width: 100%;
      height: 100%;
      @include flexbox($direction: column, $alignItems: center);
      gap: 24px;
      @include paddingX(16);
      @include paddingY(32);
      border: 4px solid $black;
      text-align: center;

      @include mq(medium) {
        @include paddingX(24);
        border-width: 6px;
        width: 47%;
        opacity: 0;
        height: unset;

      }
      @include mq(tablet) {
        @include paddingX(32);
        @include paddingY(48);

      }

      @include mq(hd) {
        width: 31%;
      }

      @include mq(fullHD) {
        @include paddingX(56);
        @include paddingY(64);
      }


      &:nth-child(1) {

        @include mq(medium) {
          @include marginT(38)
        }
        @include mq(hd) {
          @include marginT(68)
        }


      }
      &:nth-child(3) {

        @include mq(medium) {
          @include marginT(48)
        }
        @include mq(hd) {
          @include marginT(88)
        }

      }

      &:nth-child(4) {

        @include mq(mobile) {
          @include marginT(-20)
        }

        @include mq(tablet) {
          @include marginT(-30)
        }

      }
      &:nth-child(5) {

        //@include mq(mobile) {
        //  @include marginT(-20)
        //}
        //
        @include mq(mobile) {
          @include marginT(40)
        }

        @include mq(hd) {
          @include marginT(-100)
        }
      }
      &:nth-child(6) {

        @include mq(medium) {
          @include marginT(-80)
        }

        @include mq(hd) {
          @include marginT(0)
        }
      }


      &-avatar {
        max-width: 96px;

        img {
          max-width: 100%;
          border-radius: 50%;
        }

        @include mq(small) {
          max-width: 128px;
        }
        @include mq(medium) {
          max-width: 164px;
        }
        @include mq(hd) {
          max-width: 190px;
        }

      }

      &-name {
        background: $accent;
        transform: rotate(-2.5deg);
        color: $white;
        font-family: 'Geologica', sans-serif;
        font-weight: 500;
        white-space: nowrap;
      }

      &-position {
        font-weight: 700;

        @include mq(small) {
          @include get-font(12);
          line-height: 100%;
        }
        @include mq(medium) {
          @include get-font(16);
        }
        @include mq(tablet) {
          @include get-font(24);
          line-height: 133%;
        }

      }

      &-description {

        @include mq(small) {
          @include get-font(14);
          line-height: 140%;
        }

        @include mq(medium) {
          @include get-font(18);
        }
      }

      &-location {
        font-weight: 700;
        @include mq(small) {
          @include get-font(12);
          line-height: 100%;
        }
        @include mq(medium) {
          @include get-font(16);
        }
      }

      &-linkedin {
        text-decoration: unset;
        color: $black;
        text-transform: uppercase;
        font-family: Geologica, sans-serif;
        font-weight: 600;
        @include flexbox( $alignItems: center);
        @include paddingX(16);
        @include paddingT(10);
        @include paddingB(6);

        gap: 2px;
        border-bottom: 4px solid transparent;

        @include mq(small) {
          @include get-font(16);
          line-height: 100%;
          letter-spacing: -0.32px;
        }

        @include mq(medium) {
          @include get-font(20);
          letter-spacing: -0.4px;
          gap: 6px;
        }
        p{
          @include get-font(16);
          @include mq(medium) {
            @include get-font(20);
          }
        }
        img {
          max-width: 100%;
        }

        &:hover {
          border-color: $black;
          background-color: $accent;
          p{
            color: $white;

          }
          img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(209deg) brightness(107%) contrast(100%)
          }
        }

        &:active {
          background-color: $white;
          p{
            color: $black;

          }
          img {
            filter: invert(0%) sepia(56%) saturate(18%) hue-rotate(117deg) brightness(103%) contrast(76%);
          }
        }

      }
    }
  }

  .team-swiper {
    @include marginT(50);
    @include marginB(100);
    margin-left: 1rem;
  }

  .swiper-slide {
    height: auto;
    width: 80%;
    &:last-child{
      padding-right: 16px;
    }
  }

  .swiper-pagination-progressbar {
    position: relative;
    @include marginT(20);
    background: transparent;
    left: unset;
    right: 1rem;
    &-fill {
      background: $black;
    }
  }
  &.animated{
    .team-member{
      animation: slide-in-bottom 1s ease-in-out both;
      &:nth-child(2){
        animation-delay: .3s;
      }
      &:nth-child(3){
        animation-delay: .6s;
      }
      &:nth-child(4){
        animation-delay: .9s;
      }
      &:nth-child(5){
        animation-delay: 1.2s;
      }
      &:nth-child(6){
        animation-delay: 1.5s;
      }


    }
  }
}
