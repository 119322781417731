.endless-tale-page-custom{
  @include paddingY(50);

  position: relative;

  @include mq(tablet){
    @include paddingY(100);
  }
  &-arrow{
    position: absolute;
    max-width: 120px;

    @include mq(tablet){
      max-width: unset;
    }
    img{
      max-width: 100%;
    }
    &.left{
      left: 0;
      top: 60%;
      transform: translateY(-50%);
    }
    &.right{
      right: 0;
      top: 60%;
      transform: translateY(-50%);
    }
  }

  &-wrapper{

    h2{
      @include marginB(20);

      @include mq(tablet){
        @include marginB(40);
      }
    }
    &-image{
      @include marginT(48);
      @include mq(tablet){
        @include marginT(100);
      }
      img{
        width: 100%;
      }
    }
  }
}
