.about {
  overflow-x: hidden;
  color: $black;
  //margin-top: 200px;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(500px);
      transform: translateY(500px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes shake-animation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(2deg);

    }
    100% {
      transform: rotate(0deg);
    }

  }

  &__content {
    overflow: hidden;

    @include marginT(48);

    @include mq(mobile) {
      @include marginT(64);
    }

    @include mq(medium) {
      @include marginT(86);
    }

    @include mq(hd) {
      @include get-font(28);
      @include marginT(120);
    }

    &-block {
      width: 100%;
      @include mq(tablet) {
        width: 50%;
      }

      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        @include marginT(40);

        @include mq(mobile) {
          @include marginT(56);
        }

        @include mq(tablet) {
          padding-left: 32px;
          @include marginT(0);
        }

        p.mission {
          opacity: 0;
          font-weight: 600;
          font-family: Geologica-Bold, sans-serif;
          @include get-font(16);
          span {
            font-family: "IBM Plex Mono", sans-serif;
            font-weight: 300;
          }
          @include mq(medium){
            @include get-font(24);
          }
          @include mq(hd){
            @include get-font(32);
          }

        }
      }

      .btn-primary {
        opacity: 0;
        @include marginT(32);

        @include mq(mobile) {
          @include marginT(48);
        }
        @include mq(hd) {
          @include marginT(56);
        }

      }
    }
  }

  &__animation-about {
    @include flexbox($direction: column, $alignItems: center);
    @include marginT(96);
    @include marginB(100);

    @include mq(mobile) {
      @include marginT(128);
      @include marginB(128);
    }

    @include mq(medium) {
      @include marginT(180);
      @include marginB(164);
    }

    @include mq(hd) {
      @include marginT(200);
      @include marginB(200);
    }

    @include mq(full) {
      @include paddingX(140);
    }

    &__text-wrapper {
      background-color: $black;
      text-transform: uppercase;

      transform: rotate(0deg);

      h2.text {
        color: $white;
        font-family: 'IBM Plex Mono', sans-serif;
        font-weight: 300;
        opacity: 0;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        margin: unset;
        @include get-font(22);

        @include mq(medium) {
          @include get-font(44);

        }
        @include mq(tablet) {
          @include get-font(60);

        }
        @include mq(hd) {
          @include get-font(78);

        }
        @include mq(full) {
          @include get-font(96);

        }
      }

      @include mq(small) {
        @include paddingY(10.39);
        @include paddingX(13.86);
        @include marginB(19.31);
      }
      @include mq(medium) {
        @include paddingY(22.3);
        @include paddingX(29.74);
        @include marginB(41.83);
      }
      @include mq(tablet) {
        @include paddingY(30.42);
        @include paddingX(40.55);
        @include marginB(57.42);
      }
      @include mq(hd) {
        @include paddingY(39.22);
        @include paddingX(52.3);
        @include marginB(74.16);
      }
      @include mq(full) {
        @include paddingY(48);
        @include paddingX(64);
        @include marginB(90.85);
      }
    }

    .image-container {
      max-width: 220px;

      img {
        width: 100%;
      }

      @include mq(medium){
        max-width: 475px;
      }

      @include mq(tablet){
        max-width: 650px;
      }

      @include mq(hd){
        max-width: 838px;
      }

      @include mq(fullHD){
        max-width: 1025px;
      }

    }
  }

  &__title {
    text-align: center;
    display: inline-block;

  }

  &-list {
    @include flexbox($direction: row, $wrap: wrap);
    gap: 12px 16px;
    @include marginT(24);

  }

  &__info {
    opacity: 0;
    @include get-font(12);
    line-height: 100%;
    //border-radius: 1rem;
    @include paddingX(12);
    @include paddingY(8);
    text-align: justify;
    display: inline-block;
    border: 3px solid $black;
    font-weight: 700;
    border-radius: 99px;
    letter-spacing: .96px;
    @include mq(mobile) {
      @include get-font(16);
      border-width: 4px;

    }

    &::first-letter {
      // font-size: 2rem;
    }

    &::first-line {
      // font-size: 1.5rem;
    }

    &:nth-child(4n + 1) {

    }

    &:nth-child(4n + 2) {

    }

    &:nth-child(4n + 3) {

    }

    &:nth-child(4n + 4) {

    }
  }

  &.animated {

    .about__content-block {
      &:nth-child(1) {
        animation: slide-in-bottom 2s ease-in-out both;
      }
    }

    p.mission {
      animation: fade-in-top 1s ease-in-out both;
    }

    .btn-primary {
      animation: slide-in-bottom 1s ease-in-out both;
    }

    .about__info {
      animation: fade-in .5s ease-in-out both;

      &:nth-child(2) {
        animation-delay: .5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }

      &:nth-child(4) {
        animation-delay: 1.5s;
      }

      &:nth-child(5) {
        animation-delay: 2s;
      }

    }

    .about__animation-about__text-wrapper {

      .text {
        animation: fade-in .5s ease-in-out both;
        animation-delay: 1.5s;
      }

      animation: shake-animation 2s linear infinite normal both;
      animation-delay: 2s;
      transform: rotate(0deg);

    }
  }
}

