.technologies {

@keyframes gap-animation {
  from{
    gap: 0;
  }
  to{
    gap: 1.5rem;
  }
}
  @include mq(fullHD){
    @keyframes gap-animation {
      from{
        gap: 0;
      }
      to{
        gap: 2rem;
      }
    }
  }
@keyframes justify-animation {
  from{
    justify-content: flex-start;
  }
  to{
    justify-content: space-between;
  }
}
  .heading-block{
    h2{
      br{
        @include mq(medium){
          display: none;
        }
      }
    }
  }
  &-container {
    @include flexbox($justifyContent: flex-start, $wrap: wrap);
    @include marginT(48);
    @include marginB(48);
    width: 100%;
    gap: 0;
    @include mq(medium){
      @include marginT(64);
      @include marginB(128);
    }
    @include mq(tablet){
      @include marginT(37);
      @include marginB(164);
    }
    @include mq(hd){
      @include marginT(120);
      @include marginB(200);
    }


    //transition: gap 2s ease, justify-content 1s ease ;
    //transition-delay: 2s, 2s;


    .technology {
      background-color: $black;
      color: $white;
      width: 45%;
      flex-grow: 1;
      height: 99px;


      @include paddingY(22);
      @include paddingX(30);
      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include flexbox($justifyContent: center, $alignItems: center);

      @include mq(medium) {
        width: 30%;
        height: 142px;
        @include paddingY(38);

        @include paddingX(40);

      }
      @include mq(tablet) {
        width: 31%;
        height: 196px;
        @include paddingX(68);

      }
      @include mq(hd) {
        max-width: 186px;
        @include paddingX(27);

      }
      @include mq(full){
        max-width: 272px;
        @include paddingX(49);

      }
    }
  }
  &.animated{
    .technologies-container{
      //gap: 1.5rem;
      //justify-content: space-between;
      animation: gap-animation .85s ease-in-out both;
      animation-delay: 0s;
      //@include mq(hd) {
      //  gap: 2rem;
      //}
    }
  }
}
