.endless-tale-page-world {
  position: relative;
  @include paddingY(50);
  background: url("asset/image/endlessTale/world-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mq(tablet) {
    @include paddingY(100);
  }

  .container {
    max-width: 1568px;
  }

  &-arrow {
    position: absolute;
    max-width: 120px;

    @include mq(tablet){
      max-width: unset;
    }
    img {
      max-width: 100%;
    }

    &.left {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.right {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-wrapper {
    h2 {
      @include marginB(20);
      @include mq(tablet) {
        @include marginB(40);
      }
    }

    &-image {
      @include marginT(48);

      @include mq(tablet) {
        @include marginT(100);
      }

      img {
        max-width: 100%;
      }
    }
  }
}
