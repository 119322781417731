.invaders-page-hero {
  position: relative;
  @include paddingT(88);
  @include paddingB(48);

  @include mq(medium) {
    @include paddingT(150);
    @include paddingB(64);

  }

  @include mq(tablet) {
    @include paddingT(176);
    @include paddingB(82);

  }

  @include mq(hd) {
    @include paddingT(198);
    @include paddingB(160);
  }

  @include mq(bigger) {
    @include paddingT(240);
  }

  &-bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40%);

    &-gradient {
      background: radial-gradient(50% 50% at 50% 50%, rgba(27, 150, 211, 0.75) 0%, rgba(27, 150, 211, 0.00) 100%);

      filter: blur(25px);
      border-radius: 280px;
      min-height: 230px;

      @include mq(medium) {
        filter: blur(75px);
        border-radius: 528px;
        min-height: 432px;
      }

      @include mq(tablet) {
        filter: blur(100px);
        border-radius: 784px;
        min-height: 640px;
      }

      @include mq(hd) {
        filter: blur(150px);
        border-radius: 1125px;
        min-height: 920px;
      }
      @include mq(bigger) {
        filter: blur(250px);
        border-radius: 1680px;
        min-height: 1375px;
      }
    }

    img {
      width: 100%;
      position: absolute;
      top: 40%;
    }
  }

  .container {

    width: 100%;

    @include mq(medium) {
      max-width: 762px;
    }

    @include mq(tablet) {
      max-width: 934px;
    }

    @include mq(hd) {
      max-width: 1220px;
    }
    @include mq(bigger) {
      max-width: 1556px;
    }

  }

  &-wrapper {
    position: relative;
    z-index: 10;
    max-width: 328px;
    border-radius: 40px;
    border: 4px solid #0A3E6B;
    background: linear-gradient(208deg, rgba(35, 94, 127, 0.50) 15.9%, rgba(15, 82, 120, 0.05) 85.77%);
    margin: 0 auto;

    @include mq(medium) {
      max-width: unset;
      border-radius: 60px;
      border-width: 8px;
      width: 100%;
    }

    video {
      object-fit: cover;
      width: 100%;
      display: block;
      border-radius: 35px;
      @include mq(medium) {
        border-radius: 50px;
      }
    }

    video.display-desktop {
      display: none;
      @include mq(medium) {
        display: block;
      }
    }

    video.display-mobile {
      display: block;
      @include mq(medium) {
        display: none;
      }
    }

    &:hover {
      .video-controls {
        opacity: 1 !important;
      }
    }

    .video-controls {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      @include flexbox($justifyContent: space-between, $alignItems: center);
      @include paddingX(24);
      @include paddingB(24);

      @include mq(medium) {
        @include paddingX(48);
        @include paddingB(48);
      }

      &-item {
        @include flexbox($justifyContent: center, $alignItems: center);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(31, 31, 31, 0.75);
        cursor: pointer;
        @include mq(medium) {
          width: 64px;
          height: 64px;
        }

        img {
          max-width: 100%;
          display: none;

          &.active {
            display: block;
          }
        }


      }

      .play-button[data-status='played'] {
        .play-icon {
          display: none;
        }

        .pause-icon {
          display: block;
        }
      }

      .play-button[data-status='paused'] {
        .play-icon {
          display: block;
        }

        .pause-icon {
          display: none;
        }
      }

      .mute-button[data-status='unmuted'] {
        .mute-icon {
          display: block;
        }

        .unmute-icon {
          display: none;
        }
      }

      .mute-button[data-status='muted'] {
        .mute-icon {
          display: none;
        }

        .unmute-icon {
          display: block;
        }
      }

    }

    .btn-primary {
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 50%);
      left: 50%;
      border-color: #FFFFFF;
      background-color: #1FB2FB;
      color: #FFFFFF;
      max-width: 256px;
      text-transform: uppercase;
      text-align: center;
      @include paddingX(24);
      @include paddingY(12);
      line-height: 1;

      &:hover {
        border-color: #1FB2FB;
        background-color: #FFFFFF;
        color: #1FB2FB;
      }

      &:active {
        border-color: #FFFFFF;
        background-color: #1FB2FB;
        color: #FFFFFF;
      }
    }
  }

}
