.story-scape-page-craft {
  position: relative;
  @include paddingT(48);
  @include paddingB(96);

  @include mq(medium) {
    @include paddingT(100);
    @include paddingB(200);
  }
  .display-desktop {
    display: none;
    @include mq(medium) {
      display: block;
    }
  }
  .display-mobile {
    display: block;
    @include mq(medium) {
      display: none;
    }
  }

  &-image {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 590px;
    transform: translateX(-60%);

    @include mq(tablet) {
      max-width: 905px;
      transform: translateX(0);
    }

    img {
      max-width: 100%;
    }
  }

  &-wrapper {
    position: relative;
    z-index: 3;


    &-actions {
      @include flexbox($justifyContent: center);
      gap: 40px;

      &-step {

        &:nth-child(2) {
          @include mq(medium) {
            @include marginT(180);
          }
        }

        img {
          display: block;
          margin: 0 auto;
          border-radius: 24px;
          max-width: 100%;

          @include mq(medium) {
            border-radius: 35px;
          }
        }
      }
    }
  }
}
