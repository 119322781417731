.contact {
  position: relative;
  z-index: 3;
  background: $black;
  overflow-x: hidden;
  @include paddingT(64);
  @include paddingB(128);

  @include mq(mobile) {
    @include paddingT(86);

    @include paddingB(164);
  }
  @include mq(medium) {

    @include paddingT(120);
    @include paddingB(200);
  }
  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }
  .create-future-banner {
    overflow: hidden;
    display: inline-block;
    background: $white;
    padding-left: 2rem;
    @include marginB(48);
    @include paddingY(24);
    transform: rotate(-2.5deg);
    //white-space: nowrap;
    text-align: center;
    width: 102%;
    @include mq(medium){
      @include marginB(86);
    }
    @include mq(tablet){
      @include marginB(96);
    }
    @include mq(hd){
      @include marginB(128);
    }

    &-wrapper{
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      animation: marquee 15s linear infinite;
      font-size: 0;

      h2{
        display: inline-block;
        &:after{
          content: '';
          display: inline-block;
          width: 35px;
          height: 13px;
          flex-shrink: 0;
          border-radius: 999px;
          border: 2px solid $black;
          @include marginX(10);
          transform: translateY(-50%);
          @include mq(medium){
            width: 55px;
            height: 25px;
          }
          @include mq(medium){

            border: 3px solid $black;
          }
          @include mq(tablet){
            width: 60px;
            height: 30px;
          }
          @include mq(hd){
            width: 65px;
            height: 35px;
          }

          @include mq(full){
            width: 80px;
            height: 45px;
            margin-left: 30px;
            margin-right: 30px;
            border: 4px solid $black;
          }
        }
      }
    }
  }

  &-form {

    input, textarea {
      border: unset;
      border-radius: unset;
      background: transparent;
      width: 100%;
      position: relative;
      border-bottom: 4px solid $white;
      padding: 1.5rem 2rem 1.5rem 1rem;
      color: $white;
      font-family: "IBM Plex Mono", sans-serif;
      @include get-font(18);
      line-height: 140%;
      font-weight: 400;
      letter-spacing: .54px;

      @include mq(mobile) {
        @include get-font(28);
      }

      @include mq(hd) {
        @include get-font(40);
      }

      &::placeholder {
        color: $white;
        opacity: .5;

      }

      &:focus-visible {
        outline: unset;
      }
    }

    textarea {
      resize: none;
      height: 100%;
    }

    .input-box {
      position: relative;

      &-error{
        display: none;
        position: absolute;
        bottom: -40px;
        left: 0;
        margin: unset;
        color: #FF1A1A;
        font-family: 'IBM Plex Mono', sans-serif;
        @include get-font(12);
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 2.4px;
        @include mq(medium){
          @include get-font(16);
          line-height: 16px;
        }
        @include mq(hd){
          @include get-font(24);
          line-height: 32px;
        }
      }
      &.message {
        height: 100%;
      }
      &.invalid{
        input,textarea{
          border-color: #FF1A1A;
        }

        .input-box-error {
          display: block;
        }
      }
    }

    &-inputs {
      &:nth-child(1) {
        @include paddingX(0);
        @include marginB(24);
        @include flexbox($direction: column);
        gap: 1.5rem;

        @include mq(mobile) {
          gap: 2rem;
          @include marginB(32);
        }

        @include mq(medium) {
          gap: 3rem;
        }
        @include mq(tablet) {
          padding-right: 2rem;
          @include marginB(0);
        }
        @include mq(hd) {
          gap: 4rem;
        }
      }

      &:nth-child(2) {
        @include paddingX(0);
        @include mq(tablet){
          padding-left: 2rem;
        }
      }
    }

    &-button {
      @include marginT(32);
      @include paddingX(0);
      margin-left: auto;
      display: flex;
      @include mq(medium) {
        @include marginT(48);
      }
      @include mq(tablet) {
        @include marginT(56);
        padding-left: 2rem;

      }
      @include mq(hd) {
        @include marginT(64);
      }


      .btn-primary {
        border-color: $white;
        margin-left: auto;


        @include mq(medium) {
          margin-right: 60px;
        }
        @include mq(tablet){
          margin: unset;

        }
        &:active{
          background: transparent;
            color: $white;
        }
      }
    }
  }

}
