.modal{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 16;
  background-color: rgba(0,0,0, .4);
  @include paddingX(16);

  @include flexbox( $justifyContent: center, $alignItems: flex-start);
    &.hidden{
      display: none;
    }
  &-wrapper{
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    @include flexbox($direction: column, $justifyContent: center, $alignItems: center);
    background-color: $white;
    @include paddingX(64);
    @include paddingY(32);
    width: 100%;
    max-width: 640px;

    min-height: 280px;
    text-align: center;

    @include mq(medium){
      min-height: 360px;

    }
    h4{
      margin: unset;
    }
    p{
      @include get-font(14);
      @include mq(mobile){
        @include get-font(18);
      }
    }

    &-close{
      cursor: pointer;
      @include flexbox( $justifyContent: center, $alignItems: center);
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px;
      background-color: $accent;
      @include mq(medium){
        width: 64px;
        height: 64px;
      }
    }
  }
}
