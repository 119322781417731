.story-scape-page{
  font-family: 'Outfit', sans-serif;
  background-color: #0A0F19;
  background-image: url("asset/image/storyScape/main_bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top left;
  overflow-x: hidden;
  &-wrapper{
    .container{
      //@include mq(full){
      max-width: 1328px;
      //}
    }
  }

  header{
    background-color: #0A0F19;

    @include mq(medium){
      background-color: $white;
    }
  }
  .contact{
    background: unset;
    &-title{
      text-align: left;
      position: relative;
      @include marginB(48);
      .stars{
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        @include mq(medium){
          top: -100px;

        }
        img{
          max-width: 100%;
        }
      }
      .lines{
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        @include mq(medium){
          top: 45px;

        }
        img{
          max-width: 100%;
        }
      }
      @include mq(medium){
        @include marginB(100);
        text-align: center;
      }

      h2{
        position: relative;
        z-index: 3;
        color: #FFF;
        font-family: 'Lexend', sans-serif;
        @include get-font(32);
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -.96px;
        margin: unset;
        text-transform: unset;
        @include marginB(20);

        span{
          background: var(--font-gradient, linear-gradient(224deg, #8A8376 14.97%, #FFECCA 49.19%, #8A8376 85.92%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @include mq(medium){
          @include get-font(58);
          letter-spacing: -1.74px;
          @include marginB(24);
        }
      }
      p{
        position: relative;
        z-index: 3;
        color: $white;
        font-family: 'Outfit', sans-serif;
        @include get-font(16);
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.48px;
        margin: unset;
        span{
          background: var(--font-gradient, linear-gradient(224deg, #8A8376 14.97%, #FFECCA 49.19%, #8A8376 85.92%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        @include mq(medium){
          @include get-font(32);
          letter-spacing: -.96px;
        }
      }
    }

    input, textarea{
      font-family: 'Outfit', sans-serif;
      font-weight: 400;
    }

    .btn-primary{
      border: unset;
      background-color: #4F42D3;
      &:hover{
        background-color: #4F42D3;
      }
    }
  }

  .footer{
    background: #4F42D3;
  }
  section.project {
    min-height: 470px;
    background:$white;
    @include mq(mobile){
      min-height: 650px;
    }
    @include mq(medium) {
      min-height: 795px;
    }
    @include mq(tablet) {
      min-height: 993px;
    }
    @include mq(hd) {
      min-height: 1144px;
    }
    @include mq(full) {
      min-height: 1322px;
    }
    @include mq(bigger){
      min-height: 1472px;
    }
    .project-bg {

      &:after {
        background-color: #0A0F19;
        height: 210px;

        box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 20px 0px 0px #1F1F1F;

        @include mq(mobile){
          height: 290px;
        }
        @include mq(medium){
          height: 350px;
        }
        @include mq(tablet){
          height: 540px;
          box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;

        }
        @include mq(hd){
          height: 630px;
        }
        @include mq(bigger){
        }
        @include mq(full){
          height: 820px;
        }
      }
      &:before{
        @include mq(tablet){
          height: 36px;
        }
        @include mq(bigger){
          height: 56px;
        }
      }
    }
  }
}
.story-scape-title{
  @include marginB(48);

  @include mq(tablet){
    @include marginB(100);
  }

  h2{
    font-family: 'Lexend', sans-serif;
    text-transform: unset;
    @include get-font(32);
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -.96px;
    background: var(--gradient-beige, linear-gradient(180deg, #F1E0C1 0%, #D7C5A7 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: unset;
    @include marginB(20);

    @include mq(medium){
      text-align: left;
      @include get-font(72);
      letter-spacing: -2.16px;
    }
  }
  p{
    color: $white;
    @include get-font(16);
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -.48px;
    text-align: center;
    margin: unset;
    @include mq(medium){
      text-align: left;
      @include get-font(32);
      letter-spacing: -.96px;
    }
  }
}
