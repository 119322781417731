.story-scape-page-benefits{
  @include paddingY(48);

  @include mq(medium){
    @include paddingY(100);
  }
  &-wrapper{
    @include flexbox($direction: column, $alignItems: center, $justifyContent: space-between);
    gap: 48px;

    @include mq(medium){
      gap: 35px;
      flex-direction: row;
    }

    &-content{
      width: 100%;
      @include flexbox($direction: column, $alignItems: flex-start, $justifyContent: space-between);
      gap: 48px;
      @include mq(medium){
        width: 60%;
        gap: 80px;
      }

      &-item{
        @include flexbox($direction: column);
        gap: 20px;

        @include mq(medium){
          gap: 16px;
        }

        h3{
          margin: unset;
          font-family: 'Lexend', sans-serif;
          @include get-font(32);
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -.96px;
          background: var(--gradient-beige, linear-gradient(180deg, #F1E0C1 0%, #D7C5A7 100%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @include mq(medium){
            @include get-font(48);
            letter-spacing: -1.44px;
          }
        }
        p{
          margin: unset;
          color: $white;
          @include get-font(16);
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.48px;

          @include mq(medium){
            @include get-font(28);
            letter-spacing: -.84px;
            font-weight: 500;

          }
        }
      }
    }
    &-image{
      width: 95%;

      @include mq(medium){
        width: 37%;
      }
      img{
        max-width: 100%;
      }
    }
  }
}
