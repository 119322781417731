.endless-tale-page-dive{
  position: relative;
  @include paddingT(100);
  @include paddingB(50);

  @include mq(tablet){
    @include paddingT(140);
    @include paddingB(140);
  }
  &-arrow{
    position: absolute;
    img{
      max-width: 100%;
    }
    &.top{
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.left{
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.right{
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &:before{
    content: '';
    position: absolute;
    z-index: 2;
    top: -1px;
    left: 0;
    right: 0;
    height: 90px;
    background: linear-gradient(180deg, #0A0F19 0%, rgba(10, 15, 25, 0.00) 100%);
    @include mq(tablet){
      //height: 222px;
      height: 175px;
    }
  }
  &-wrapper{
    @include flexbox($direction: column);
    gap: 24px;

    @include mq(medium){
      gap: 64px;
    }


    &-video{
      position: relative;
      display: flex;
      cursor: pointer;
      background: url("asset/image/endlessTale/main-video-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 8px;
      @include mq(tablet){
        padding: 32px;
      }
      .play-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 34px;
        height: 34px;
        border-radius: 4px;
        border: 1px solid #ECD3A4;
        background: var(--gradient-blue, linear-gradient(180deg, #4C4BD6 0%, #3332B7 100%));
        box-shadow: 0 10px 124px 0 rgba(72, 71, 209, 0.60);
        cursor: pointer;

        @include flexbox($justifyContent: center, $alignItems:center);
        @include mq(medium){
          width: 130px;
          height: 130px;
          border-radius: 25px;
          border: 2px solid #ECD3A4;

        }

        img{
          max-width: 12px;
          @include mq(medium){
            max-width: 40px;
          }

        }
      }
      video{
        max-width: 100%;
        border-radius: 35px;
        margin: 0 auto;
        @include mq(medium) {
          border-radius: 50px;
        }
      }
    }
    p{
      color: $white;
      text-align: center;
      font-family: 'Texturina', sans-serif;
      @include get-font(12);
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;
      margin: unset;

      @include mq(medium){
        font-weight: 500;
        @include get-font(28);
        letter-spacing: -.84px;
      }

    }
  }
}
