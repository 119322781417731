.amplifier-hero{
  background: #000000;
  //min-height: 738px;

  &-wrapper{
    position: relative;
    @include paddingT(64);

    @include mq(medium){
      @include paddingT(86);
    }
    @include mq(tablet){
      @include paddingT(112);
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 62px;
      background: linear-gradient(0deg, #3485F7 0%, rgba(52, 133, 247, 0.00) 100%);

      @include mq(medium){
        height: 134px;
      }
      @include mq(tablet){
        height: 180px;
      }
      @include mq(hd){
        height: 240px;
      }
      @include mq(full){
        height: 337px;
      }

    }

    video{
      display: block;
      width: 100%;
    }

    &-logo{
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include paddingT(88);
      @include mq(medium){
        @include paddingT(110);
      }
      @include mq(tablet){
        @include paddingT(144);
      }
      @include mq(hd){
        @include paddingT(152);
      }
      @include mq(full){
        @include paddingT(160);
      }

      .logo-image{
        width: 140px;
        img{
          max-width: 100%;
        }
        @include mq(tablet){
          width: 167px;
        }
        @include mq(hd){
          width: 194px;
        }
        @include mq(full){
          width: 243px;
        }
      }
    }

    &-tags{
      position: absolute;
      bottom: 0;
      width: 100%;
      @include paddingB(32);

      @include mq(tablet){
        @include paddingB(37);
      }

      @include mq(hd){
        @include paddingB(75);
      }

      @include mq(full){
        @include paddingB(48);
      }

      .tags-wrapper{
        @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
        gap: 12px;

        @include mq(medium){
          gap: 16px;
        }

        p{
          @include flexbox($justifyContent: center, $alignItems: center);
          margin: unset;
          padding: 6px 14px;
          border-radius: 99px;
          border: 2px solid $white;
          color: $white;
          font-family: 'Outfit', sans-serif;
          @include get-font(12);
          font-weight: 700;
          line-height: 100%;
          letter-spacing: .96px;

          @include mq(medium){
            @include get-font(14);
            border-width: 3px;
            letter-spacing: 1.12px;
            padding: 9px 21px;
          }

          @include mq(tablet){
            @include get-font(16);
            border-width: 4px;
            letter-spacing: 1.28px;
            padding: 8px 20px;
          }
        }
      }
    }
  }
}
