section.project {
  position: relative;
  @include paddingT(64);
  .heading-block{
    h2{
      br{
        @include mq(hd){
          display: none;
        }
      }
    }
  }
  h2,
  h3 {
    color: $white;
  }
  h4,p {
    color: $black;
  }
  .line {
    background-color: $white;

    &:after {
      background-color: $black;
    }
  }

  @include mq(small) {
    min-height: 520px;
  }
  @include mq(mobile){
    @include paddingT(86);
    min-height: 650px;
  }
  @include mq(medium) {
    min-height: 795px;
    @include paddingB(164);
    @include paddingT(64);
  }

  @include mq(tablet) {
    min-height: 993px;
    @include paddingB(200);
    @include paddingT(183);
  }
  @include mq(hd) {
    min-height: 1144px;
    @include paddingY(201);
  }
  @include mq(full) {
    min-height: 1322px;
    overflow-x: clip;
  }

  .project-bg {
    &::before {
      width: 100%;
      content: '';
      position: absolute;
      top: 0;
      background-color: $black;
      @include mq(small) {
        height: 33px;
        top: -33px;
      }
      @include mq(medium) {
        height: 38px;
      }
      @include mq(tablet) {
        height: 38px;
      }
      @include mq(hd) {
        height: 38px;
      }
      @include mq(full) {
        height: 55px;
        top: -55px;
      }
    }

    &::after {
      width: 100%;
      content: '';
      position: absolute;
      background-color: $accent;
      top: 0;
      height: 290px;
      box-shadow: 0px -32px 0px 0px #1F1F1F, 0px 16px 0px 0px #1F1F1F;
      @include mq(mobile) {
        height: 370px;
      }
      @include mq(medium) {
        box-shadow: 0px -64px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;

        height: 470px;
      }
      @include mq(tablet) {
        height: 690px;
      }
      @include mq(hd) {
        height: 720px;
      }
      @include mq(full) {
        height: 908px;
        transform: rotate(-1deg);
        top: -17px;
        right: -8px;
        width: 110%;
      }
    }
  }

  .heading-block {
    position: relative;
    z-index: 10;
  }

  .swiper-wrapper {
    align-items: center;
    opacity: 0;
    &.visible{
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom-active-slide {
    0% {
      -webkit-transform: translateY(300px);
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left-prev-slide {
    0% {
      -webkit-transform: translateX(-300px);
      transform: translateX(-300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-right-next-slide {
    0% {
      -webkit-transform: translateX(300px);
      transform: translateX(300px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .swiper {
    position: relative;
    z-index: 10;
    overflow-y: inherit;

    img {
      width: 100%;
    }

    img {
      max-width: 1128px;
    }

    .swiper-frame {
      border-radius: 1000px;
      border: 4px solid $white;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      width: 275px;
      height: 135px;
      &__include {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 1000px;
        border: 6px solid $black;
        background-color: $white;
      }
    }

    @include mq(mobile) {
      .swiper-frame {
        width: 410px;
        height: 213px;
        border-width: 9px;
        &__include{
          border-width: 11px;
        }
      }
    }
    @include mq(medium) {
      .swiper-frame {
        width: 550px;
        height: 270px;
        border-width: 7px;
        &__include{
          border-width: 7px;
        }
      }
    }
    @include mq(tablet) {
      .swiper-frame {
        width: 707px;
        height: 362px;
        border-width: 16px;
        &__include{
          border-width: 16px;
        }
      }
    }
    @media(min-width: 1320px) {
      .swiper-frame {
        width: 834px;
        height: 423px;
      }
    }
    @include mq(hd) {
      .swiper-frame {
        width: 918px;
        height: 456px;
      }
    }
    @include mq(full) {
      @include marginT(130);
      .swiper-frame {
        width: 1053px;
        height: 520px;
      }
    }
    @include mq(bigger) {
      .swiper-frame {
        width: 1190px;
        height: 590px;
      }
    }

    .swiper-frame {
      position: absolute;
      //border: 4px solid $white;

    }
  }

  .swiper {
    width: 100%;
    height: 200px;
    @include mq(mobile){
      height: 320px;
    }
    @include mq(medium){
      height: 400px;
    }
    @include mq(tablet){
      height: 500px;
    }
    @include mq(hd){
      height: 610px;
    }



  }

  &.animated{

    .swiper-wrapper.first-animation{
      .swiper-slide-active{
        animation: slide-in-bottom-active-slide 1s ease-in-out both ;
        .swiper-slide-title{
          animation-delay: 1s;
        }
      }
      .swiper-slide-prev{
        animation: slide-in-left-prev-slide 0.5s ease-in-out both;

      }
      .swiper-slide-next{
        animation: slide-in-right-next-slide 0.5s ease-in-out both;
      }

    }
  }
  .swiper-slide {
    // text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    //-webkit-transform: translateZ(0);
    //-webkit-backface-visibility: hidden;
  }

  .swiper-slide {
    width: 60%;
    a{
      display: flex;
      justify-content: center;
      -webkit-user-drag: none;
    }
    .content-slide {
      .backdrop {
        //height: 100%;
        background-color: darkgray;
        background-blend-mode: luminosity;
        background-size: cover;
        background-position: center center;
        height: 91px;
        opacity: 1;
        transition: opacity .6s ease-in;
        @include mq(mobile) {
          height: 131px;
        }
        @include mq(medium) {
          height: 183px;
        }
        @include mq(tablet) {
          height: 225px;
        }
        @media(min-width: 1320px) {
          height: 272px;
        }
        @include mq(hd) {
          height: 300px;
        }
        @include mq(full) {
          height: 347px;
        }
        @include mq(bigger) {
          height: 396px;
        }
      }
    }

    img {
      width: 195px;
      transition: .6s all ease-in;
      @include mq(mobile) {
        width: 280px;
      }

      @include mq(medium) {
        width: 392px;
      }
      @include mq(tablet) {
        width: 482px;
      }
      @media(min-width: 1320px) {
        width: 580px;
      }
      @include mq(hd) {
        width: 640px;
      }
      @include mq(full) {
        width: 740px;
      }
      @include mq(bigger) {
        width: 846px;
      }
    }
    video {
      width: 195px;
      transition: .6s all ease-in;
      border-radius: 964px;
      object-fit: cover;
      height: 91px;

      @include mq(mobile) {
        width: 280px;
        height: 131px;

      }

      @include mq(medium) {
        width: 392px;
        height: 183px;

      }
      @include mq(tablet) {
        width: 482px;
        height: 226px;

      }
      @media(min-width: 1320px) {
        width: 580px;
        height: 271px;

      }
      @include mq(hd) {
        width: 640px;
        height: 299px;

      }
      @include mq(full) {
        width: 740px;
        height: 346px;

      }
      @include mq(bigger) {
        width: 846px;
        height: 396px;

      }
    }


    .content-slide {
      position: relative;
      border-radius: 723px;

      .backdrop {
        transition: opacity .6s ease-in;
        opacity: 1;
        content: '';
        border-radius: 723px;
        position: absolute;
        width: 100%;
        // height: 100%;
        top: 0;
        right: 0;
      }
    }

    &.swiper-slide-next {
      //justify-content: flex-start;

    }

    &.swiper-slide-prev {
      //justify-content: flex-end;


    }

    &.big-slide-active {
      .content-slide {
        .backdrop {
          display: none;
          background-image: none !important;
        }
      }
    }
  }


  .swiper-slide.big-slide-active {

    img {

      // width: 100%;
      position: relative;
      transform: scale(1.34);
      margin-top: 4px;
      transition: .5s all linear;

      @include mq(hd){
        @include marginT(13);
      }
      @include mq(bigger){
        @include marginT(9);
      }
    }
    video {

      // width: 100%;
      position: relative;
      transform: scale(1.34);
      margin-top: 4px;
      transition: .5s all linear;

      @include mq(hd){
        @include marginT(13);
      }
      @include mq(bigger){
        @include marginT(9);
      }
    }

    position: relative;

    &::after {
      content: '';
    }

    &::before {
      content: '';
    }
    .swiper-slide-title{
      opacity: 1;
    }
  }

  .swiper-slide-active{
    img {

      // width: 100%;
      position: relative;
      transform: scale(1.34);
      margin-top: 4px;
      transition: .6s all ease-in;

      @include mq(hd){
        @include marginT(13);
      }
      @include mq(bigger){
        @include marginT(9);
      }
    }
    video {

      // width: 100%;
      position: relative;
      transform: scale(1.34);
      margin-top: 4px;
      transition: .5s all linear;

      @include mq(hd){
        @include marginT(13);
      }
      @include mq(bigger){
        @include marginT(9);
      }
    }

    position: relative;

    &::after {
      content: '';
    }

    &::before {
      content: '';
    }
    .swiper-slide-title{
      opacity: 1;
    }
    .content-slide {
      .backdrop {
        //display: none;
        //background-image: none !important;
        opacity: 0;

      }
    }
  }
  .swiper-slide-active.initial{
    animation: slide-in-bottom-active-slide 1s ease-in-out both ;
    animation-iteration-count: 1;

    .swiper-slide-title{
      animation-delay: 1s;
    }
  }
  .swiper-slide-title{
    position: absolute;
    top: 100%;
    text-align: center;
    z-index: 11;
    opacity: 0;
    transition: opacity .6s ease-in;
    @include mq(tablet){
      //@include marginT(32);
    }

    @include mq(hd){
      //@include marginT(40);
    }
    h4 {
      @include marginB(12);

      @include mq(medium){
        @include marginB(16);

      }

    }
    p {
      margin: 0;
      font-weight: normal;
      font-family: 'IBM Plex Mono', sans-serif;
    }
  }
  .title-block {
    position: relative;
    text-align: center;
    z-index: 11;
    @include mq(hd){
      @include marginT(56);
    }

    h5 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      font-weight: normal;
      font-family: 'IBM Plex Mono', sans-serif;
    }
  }
}
