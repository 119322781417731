.endless-tale-page-hero{
  position: relative;
  background-image: url("asset/image/endlessTale/hero_bg_mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-height: 265px;
  @include flexbox($justifyContent: flex-end, $alignItems: center, $direction: column);
  @include marginT(64);

  @include mq(tablet){
    @include marginT(112);
    min-height: 750px;
    background-position: bottom center;
    background-image: url("asset/image/endlessTale/hero_bg.png");
  }

  &:before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 210px;
    //background: linear-gradient(0deg, #0A0F19 0%, rgba(10, 15, 25, 0.00) 100%);
    background: linear-gradient(0deg, #0A0F19 6.35%, rgba(10, 15, 25, 0.00) 100%);
    @include mq(tablet){
      //height: 222px;
      height: 490px;
    }
  }
  &-wrapper{
    margin-top: auto;
    @include flexbox($justifyContent: center, $alignItems: center, $direction: column);
    gap: 16px;
    @include paddingB(17);

    @include mq(tablet){
      gap: 122px;
      @include paddingB(60)

    }
    h1{
      //text-shadow: 0 0 40px rgba(19, 25, 46, 0.60);
      margin: unset;
      font-family: 'Texturina', sans-serif;
      @include get-font(36);
      font-weight: 700;
      background: linear-gradient(180deg, #F1E0C1 0%, #D7C5A7 100%);
      //backdrop-filter: blur(25px);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: unset;

      @include mq(tablet){
        @include get-font(124);
      }

    }
    &-tags{
      @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
      gap: 10px;

      @include mq(tablet){
        gap: 1rem;
      }
      p{
        margin: unset;
        @include flexbox($justifyContent: center, $alignItems: center);
        padding: 6px 14px;
        border-radius: 99px;
        border: 2px solid $white;
        color: $white;
        font-family: 'Outfit', sans-serif;
        @include get-font(12);
        font-weight: 700;
        line-height: 16px;
        letter-spacing: .96px;
        @include mq(tablet){
          padding: 12px 24px;
          border-width: 4px;
          @include get-font(16);
          letter-spacing: 1.28px;

        }
      }
    }
  }
}
