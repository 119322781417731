@import "01_Hero/hero";
@import "02_Info/info";
@import "03_Problems/problems";
@import "04_Reviews/reviews";
@import "05_Cases/cases";

.amplifier{

  .container{
    @include mq(hd){
      max-width: 1270px;
    }
    @include mq(full){
      max-width: 1328px;
    }
  }

  .text-block{
    background: linear-gradient(259deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.19) 42.31%, rgba(255, 255, 255, 0.25) 100%);
    padding: 16px;
    max-width: 100%;

    @include mq(medium){
      padding: 24px;
      //max-width: 340px;
      max-width: 48.2%;
    }
    @include mq(tablet){
      padding: 32px;
      //max-width: 468px;
    }
    @include mq(hd){
      @include get-font(20);
      letter-spacing: -0.6px;
      //max-width: 580px;
    }
  }
  .text-with-line{
    margin: unset;
    color: $white;
    font-family: 'Montserrat', sans-serif;
    @include get-font(14);
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.42px;
    max-width: 100%;

    @include mq(medium){
      @include get-font(16);
      letter-spacing: -0.48px;
    }
    @include mq(hd){
      @include get-font(20);
      letter-spacing: -0.6px;
    }

    &:before{
      content: '';
      width: 120px;
      height: 4px;
      border-radius: 0 4px 4px 0;
      background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      display: block;
      @include marginB(12)
    }
  }
  section.project {
    min-height: 470px;
    @include mq(mobile){
      min-height: 650px;
    }
    @include mq(medium) {
      min-height: 795px;
    }
    @include mq(tablet) {
      min-height: 993px;
    }
    @include mq(hd) {
      min-height: 1144px;
    }
    @include mq(full) {
      min-height: 1322px;
    }
    @include mq(bigger){
      min-height: 1472px;
    }
    .project-bg {

      &:after {
        background-color: #3485F7;
        //box-shadow: unset;
        height: 210px;

        box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 20px 0px 0px #1F1F1F;

        @include mq(mobile){
          height: 290px;
        }
        @include mq(medium){
          height: 350px;
        }
        @include mq(tablet){
          height: 540px;
          box-shadow: 0px -34px 0px 0px #1F1F1F, 0px 32px 0px 0px #1F1F1F;

        }
        @include mq(hd){
          height: 630px;
        }
        @include mq(bigger){
        }
        @include mq(full){
          height: 820px;
        }
      }
      &:before{
        @include mq(tablet){
          height: 36px;
        }
        @include mq(bigger){
          height: 56px;
        }
      }
    }
  }
  .contact-form{
    .btn-primary{
      background-color: #3485F7;
      &:hover{
        border-color: #3485F7;
        background-color: #FFFFFF;
        color: #3485F7;
      }
      &:active{
        border-color: #FFFFFF;
        background-color: #3485F7;
        color: #FFFFFF;
      }
    }
  }
  .footer{
    background-color: #3485F7;
  }
}

.amplifier-section-title{
  color: #285391;
  background-color: $white;
  font-family: 'Montserrat', sans-serif;
  @include get-font(24);
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -.72px;
  text-transform: capitalize;
  padding: 16px;
  margin: unset;
  flex-grow: 1;
  min-width: 100%;

  span{
    color: #3485F7
  }
  @include mq(mobile){
    min-width: 340px;
    max-width: 340px;
  }

  @include mq(medium){
    @include get-font(32);
    letter-spacing: -.96px;
    padding: 24px;
  }
  @include mq(tablet){
    @include get-font(40);
    letter-spacing: -1.2px;
    padding: 32px;
    min-width: 468px;
    max-width: 468px;

  }
  @include mq(hd){
    @include get-font(48);
    letter-spacing: -1.44px;
    min-width: 580px;
    max-width: 580px;
  }
}
