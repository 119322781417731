.amplifier-problems{
  @include paddingY(96);
  background: #000000;

  @include mq(medium){
    @include paddingY(120);
  }

  @include mq(tablet){
    @include paddingY(160);
  }

  @include mq(hd){
    @include paddingY(200);
  }
  &-wrapper{
    .amplifier-section-title{
      @include marginB(48);

      @include mq(medium){
        @include marginB(80);
      }
      @include mq(tablet){
        @include marginB(120);
      }

    }
    &-examples{
      @include flexbox($direction: column);
      gap: 40px;
      @include mq(medium){
        gap: 0;
      }
      &-item{
        @include flexbox($alignItems: flex-start, $justifyContent: flex-start, $direction: column);
        gap: 24px;

        @include mq(medium){
          flex-direction: row;
          gap: 40px;
          align-items: center;
          &.reversed{
            flex-direction: row-reverse;
            gap: 120px;
            justify-content: flex-end;
          }
        }
        @include mq(hd){
          gap: 64px;
          &.reversed{
            flex-direction: row-reverse;
            gap: 184px;
          }
        }
        &-info{
          @include flexbox($alignItems: flex-start, $justifyContent: flex-start, $direction: column);
          gap: 24px;
          flex-shrink: unset;
          h4{
            margin: unset;
            color: $white;
            font-family: 'Montserrat', sans-serif;
            @include get-font(16);
            font-weight: 600;
            line-height: 140%;
            letter-spacing: -0.32px;
            background: #DB615C;
            padding: 8px 16px;
            &.solution{
              background: #3485F7;
            }
            @include mq(medium){
              @include get-font(24);
              letter-spacing: -0.48px;
              padding: 12px 24px;
            }

            @include mq(hd){
              @include get-font(32);
              letter-spacing: -0.64px;
            }

          }
          p{
            margin: unset;
            color: rgba(255, 255, 255, 0.75);
            font-family: 'Montserrat', sans-serif;
            @include get-font(14);
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.42px;
            @include mq(medium){
              @include get-font(16);
              letter-spacing: -0.48px;
            }
            @include mq(hd){
              @include get-font(20);
              letter-spacing: -0.6px;
            }
          }
        }
        &-image{
          min-width: 160px;
          max-width: 160px;
          @include mq(medium){
            min-width: 240px;
            max-width: 240px;
          }
          @include mq(hd){
            min-width: 320px;
            max-width: 320px;
          }

          img{
            width: 100%;
            object-position: center center;
            display: block;
          }
        }
      }
    }
  }
}
