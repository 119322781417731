header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  border-bottom: 4px solid $black;
  z-index: 30;
  background-color: $white;
  transition: all .6s ease-in-out;
  transition-delay: .3s;

  @include mq(medium) {
    height: 5.375rem;
  }

  @include mq(tablet) {
    height: 7rem;
  }

  .wrapper {
    @include flexbox($direction: row, $alignItems: center, $justifyContent: space-between);
    height: 100%;
    position: relative;

    &-logo {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $black;
      @include flexbox($alignItems: center, $justifyContent: center);
      max-width: 100px;
      @include paddingX(16);
      @include paddingY(24);

      img {
        max-width: 100%;
      }

      @include mq(medium) {
        max-width: 184px;
        @include paddingX(32);

      }
      @include mq(tablet) {
        max-width: 200px;
      }
      @include mq(hd) {
        max-width: 265px;
        @include paddingX(24);


      }


    }

    &-nav {
      height: 100%;
      margin: 0 auto;
      list-style: none;
      @include flexbox($direction: row, $alignItems: center, $justifyContent: space-between);
      gap: 2rem;
      text-transform: uppercase;
      display: none;
      @include mq(tablet) {
        display: flex;
      }

      li {
        height: 100%;
        @include flexbox($alignItems: center);
        position: relative;

        &:hover {
          a.wrapper-nav-link {
            background-color: $accent;

            p {
              color: $white;
            }

            svg {
              transform: rotate(180deg);

              path {
                fill: $white;
              }
            }
          }

          .drop-down {
            display: flex;
          }
        }

        //&:active{
        //  a.wrapper-nav-link{
        //    p{
        //      color: $black;
        //    }
        //  }
        //}

        a.wrapper-nav-link {
          position: relative;
          @include flexbox($alignItems: center);
          @include get-font(18);
          line-height: 140%;
          cursor: pointer;
          padding: 4px;
          color: $black;

          p {
            margin: unset;
            @include get-font(18);
            line-height: 140%;
            color: $black;
          }

          svg {
            transition: .3s linear all;
          }

          &:active {
            p {
              color: $black;
            }

            svg {
              path {
                fill: $black;
              }
            }
          }

        }

        .drop-down {
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: -150px;
          left: 50%;
          transform: translateX(-50%);
          padding: 20px 28px;
          border-radius: 69px;
          border: 4px solid $black;
          background: $white;
          gap: 12px;

          a.drop-down-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;
            padding: 4px 8px;

            p {
              color: $black;
              text-align: center;
              font-family: 'IBM Plex Mono', sans-serif;
              @include get-font(16);
              font-weight: 700;
              line-height: 100%;
              letter-spacing: 1.28px;
              text-transform: initial;
              margin: unset;
            }

            &:hover {
              background-color: $accent;

              p {
                color: $white;
              }

              svg {
                path {
                  fill: $white;
                }
              }
            }

            &:active {
              background-color: $accent;

              p {
                color: $black;
              }

              svg {
                path {
                  fill: $black;
                }
              }
            }

          }

        }
      }
    }

    &-burger {
      background-color: $accent;
      height: 100%;
      width: 4rem;
      margin-left: auto;

      .open-menu {
        display: block;
      }

      .close-menu {
        display: none;
      }

      &.active {
        .open-menu {
          display: none;
        }

        .close-menu {
          display: block;
        }
      }

      @include flexbox($alignItems: center, $justifyContent: center);
      @include mq(medium) {
        width: 5.375rem;
      }
      @include mq(tablet) {
        display: none;
      }

      img {
        width: 36px;
        height: 36px;

        @include mq(medium) {
          width: 50px;
          height: 50px;
        }
      }

      &-nav {
        overflow: hidden;
        border-top: 4px solid $black;
        border-bottom: 64px solid $black;
        height: calc(100vh - 60px);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        //background-color: hsl(0, 0%, 95%);
        background-color: $white;
        @include flexbox($alignItems: flex-start, $justifyContent: center);

        transition: all .5s ease-in;

        ul {
          list-style: none;
          margin: unset;
          padding: unset;
          text-transform: uppercase;
          @include flexbox($alignItems: center, $direction: column);
          gap: 48px;
          @include get-font(28);
          line-height: 140%;
          @include paddingT(64);

          @include mq(mobile) {
            gap: 64px;
            @include get-font(40);
            @include paddingT(86);
          }

          li {
            .wrapper-burger-nav-link {
              @include flexbox($justifyContent: center, $alignItems: center);

              p {
                text-transform: uppercase;
                @include get-font(28);
                line-height: 140%;
                margin: unset;
                @include mq(mobile) {
                  @include get-font(40);
                }
              }
            }

            .sublinks-wrapper{
              max-height: 0;
              overflow: hidden;
              transition: all 1s ease-in-out;
            }

            .wrapper-burger-nav-sublinks {
              @include flexbox($justifyContent: center, $alignItems: center, $direction: column);
              border-radius: 99px;
              border: 4px solid $black;
              background: $white;
              padding: 20px 52px;
              gap: 12px;
              @include marginT(24);

              a {
                @include flexbox($justifyContent: center, $alignItems: center);
                gap: 8px;
                padding: 6px 12px;

                p {
                  color: $black;
                  text-align: center;
                  margin: unset;
                  font-family: 'IBM Plex Mono', sans-serif;
                  @include get-font(16);
                  font-weight: 700;
                  line-height: 100%;
                  letter-spacing: 1.28px;
                  text-transform: capitalize;

                }

                &:hover {
                  background-color: $accent;

                  p {
                    color: $white;

                  }

                  svg {
                    path {
                      fill: $white;
                    }
                  }
                }

                &:active {
                  p {
                    color: $black;

                  }

                  svg {
                    path {
                      fill: $black;
                    }
                  }
                }
              }
            }

            //&:hover {
            //
            //  .wrapper-burger-nav-link {
            //    background-color: $accent;
            //  }
            //
            //  .wrapper-burger-nav-link a, .wrapper-burger-nav-link p {
            //    color: $white;
            //  }
            //
            //  .wrapper-burger-nav-link svg {
            //    //transform: rotate(180deg);
            //    path {
            //      fill: $white;
            //    }
            //  }
            //
            //}

            &.expanded {

              .wrapper-burger-nav-link {
                background-color: $accent;
              }

              .sublinks-wrapper {
                max-height: 500px;
              }

              .wrapper-burger-nav-link a, .wrapper-burger-nav-link p {
                color: $white;
              }

              .wrapper-burger-nav-link svg {
                transform: rotate(180deg);
                path {
                  fill: $white;
                }
              }
            }

            a {
              padding: 4px;

              &:active {
                color: $black;
              }
            }

            svg {
              transition: .3s linear all;
            }
          }
        }

        @include mq(medium) {
          height: calc(100vh - 82px);

        }

        &.closed {
          height: 0;
          border-bottom-width: 0;
        }

        &.opened {
          @keyframes fade-in-fwd {
            0% {
              -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
              opacity: 0;
            }
            100% {
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              opacity: 1;
            }
          }

          ul {
            animation: fade-in-fwd 0.8s ease-in-out both;
            animation-delay: .5s;

          }
        }
      }
    }

    &::after {
      content: '';
      position: relative;
      top: 0;
      right: 0;
      display: block;
      height: 100%;
      background-color: $black;
      width: 1rem;
      @include mq(mobile) {
        width: 2rem;
      }
      @include mq(tablet) {
        width: 4rem;
        position: absolute;
      }
    }
  }

}

@keyframes header-animation {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

header.animated {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  animation: 1.3s header-animation ease-in-out;
  //animation-delay: .7s;
}

header.showed {
  transform: translateY(0);
  transition: all .6s ease-in-out;
  transition-delay: .3s;
}

header.hidden {
  transform: translateY(-100%);
  transition: all .6s ease-in-out;
  transition-delay: .3s;
}
