.story-scape-page-adventures{
  @include paddingY(48);

  @include mq(medium){
    @include paddingY(100);
  }

  .display-desktop {
    display: none;
    @include mq(medium) {
      display: block;
    }
  }

  .display-mobile {
    display: block;
    @include mq(medium) {
      display: none;
    }
  }

  .stars-image{
    position: absolute;
    img{
      max-width: 100%;
    }
  }
  &-wrapper{

    &-examples{
      @include flexbox($justifyContent: center);
      gap: 30px;
      position: relative;


      .left-star{
        left: -100px;
        top: 0;

      }
      .right-star{
        right: -130px;
        top: -40px;
      }
      .top-star{

      }
      .bottom-star{

      }

      &-item{
        position: relative;
        z-index: 3;
        &:nth-child(1), &:nth-child(3){

          @include mq(medium) {
            @include marginT(130);
          }
        }
        img{
          max-width: 100%;
          display: block;
          margin: 0 auto;
          border-radius: 24px;

          @include mq(medium) {
            border-radius: 35px;
          }
        }
      }
    }
  }
  .adventures-slider{
    position: relative;

    .bottom-star{
      bottom: -90px;
      max-width: 160px;
      transform: rotate(134deg);
      right: 0;
    }
    .top-star{
      top: -40px;
      max-width: 120px;
    }
  }
}
