$col-sm:        480px;  // (max-width: 480px)
$col-md:        768px;  // (max-width: 768px)
$col-lg:        1024px;  // (max-width: 1024px)
$col-xl:        1366px; // (max-width: 1366px)
$col-xxl:       1920px; // (max-width: 1920px)

$breakpoints: (
  zero: 0px,
  def: 320px,
  small: 360px,
  mobile: 480px,
  medium: 768px,
  large: 960px,
  tablet: 1024px,
  wide: 1200px,
  hd: 1366px,
  full: 1680px,
  bigger: 1780px,
  fullHD: 1920px,
);

$black: #1F1F1F;
$accent: #FF4E22;
$white: #fff;

$column-count: 12 !default;
$gutter-size: 0.5rem !default;
$medium: 48em; //640px
$large: 64em; //1024px

$animate-duration: 1s;
$animate-delay: 1s;
$animate-repeat: 1;
