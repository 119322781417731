.endless-tale-page-artifacts{
  @include paddingY(50);
  overflow: hidden;
  @include mq(tablet){
    @include paddingY(100);
  }
  &-wrapper{
    h2{
      @include marginB(20);

      @include mq(tablet){
        @include marginB(40);
      }
    }
    &-items{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 35px;
      flex-wrap: wrap;
      @include marginT(48);

      @include mq(tablet){
        @include marginT(100);
        flex-wrap: nowrap;
        gap: 70px;


      }

      .item{
        width: 40%;

        @include mq(tablet){
          width: 25%;
        }
        img{
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }

        &:nth-child(1){
          transform: rotate(-7deg);
          //margin-top: 56px;
          @include mq(tablet){
            transform: rotate(-15deg);
            @include marginT(56);
          }
        }
        &:nth-child(2){
          transform: rotate(7deg);
          @include marginT(-20);

          @include mq(tablet){
            transform: rotate(-7deg);
            @include marginT(0);
          }
        }
        &:nth-child(3){
          transform: rotate(-7deg);
          @include marginT(20);

          @include mq(tablet){
            transform: rotate(7deg);
            @include marginT(0);

          }
        }
        &:nth-child(4){
          transform: rotate(7deg);
          //margin-top: 56px;
          @include mq(tablet){
            @include marginT(56);
            transform: rotate(15deg);
          }
        }

      }
    }
  }
}
