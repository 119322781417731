.invaders-page-access{
  position: relative;
  @include paddingT(48);
  text-align: center;

  @include mq(medium){
    @include paddingT(82);
  }

  @include mq(hd){
    @include paddingT(100);
  }


  &-dots{
    position: absolute;
    width: 100%;
    bottom: 80px;

    @include mq(medium){
      bottom: 310px;
    }
    @include mq(hd){
      top: 100px;
    }

    img{
      max-width: 100%;
    }

  }

  &-wrapper{
    position: relative;
    z-index: 10;
    @include flexbox($direction: column, $alignItems: center );
    @include marginB(96);

    @include mq(medium){
      @include marginB(164);
    }
    @include mq(hd){
      @include marginB(200);
    }
    @include mq(bigger){
      @include marginB(336);
    }

    h3 {
      z-index: 3;
      color: #FFFFFF;
      @include get-font(24);
      font-weight: 800;
      line-height: 32px;
      letter-spacing: -0.24px;
      text-transform: uppercase;
      margin: unset;
      @include marginB(34);

      @include mq(medium) {
        @include get-font(32);
        line-height: 40px;
        letter-spacing: -0.32px;
      }
      @include mq(tablet) {
        @include marginB(42);
      }

      @include mq(hd) {
        @include get-font(40);
        line-height: 48px;
        letter-spacing: -0.4px;
      }

      @include mq(bigger) {
        @include get-font(64);
        line-height: 72px;
        letter-spacing: -0.64px;
      }


    }

    p {
      z-index: 3;

      color: rgba(255, 255, 255, 0.75);
      @include get-font(16);
      font-weight: 500;
      line-height: 150%; /* 36px */
      letter-spacing: -0.32px;
      max-width: 1050px;
      margin: 0 auto;
      @include marginB(24);

      @include mq(tablet) {
        @include marginB(32);
      }
      @include mq(hd) {
        @include get-font(20);
        letter-spacing: -0.4px;
      }

      @include mq(bigger) {
        @include get-font(24);
        letter-spacing: -0.48px;
      }

    }

    .banner-block{
      //max-width: 550px;
      @include paddingY(16);
      @include paddingX(28);
      background-image: url("asset/image/invaders/banner-cta-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include marginB(40);

      @include mq(medium){
        @include marginB(56);
        @include paddingX(58);
      }
      @include mq(hd){
        @include paddingY(24);
        @include paddingX(66);
        @include marginB(64);
      }
      @include mq(bigger){

      }



      p{
        margin: unset;
        color: #1FB2FB;
        text-align: center;
        @include get-font(20);
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        @include mq(medium){
          @include get-font(24);
          line-height: 32px;
          letter-spacing: -0.24px;
        }
        @include mq(hd){
          @include get-font(28);
          line-height: 32px;
          letter-spacing: -0.28px;
        }
        @include mq(bigger){
          @include get-font(32);
          line-height: 40px;
          letter-spacing: -0.32px;
        }


      }
    }

    form{
      width: 100%;
    }

    &-form{
      margin: 0 auto;
      @include flexbox($direction: column, $alignItems: center, $justifyContent: space-between);
      max-width: 1200px;
      gap: 32px;

      @include mq(tablet){
        flex-direction: row;
        align-items: flex-end;
      }
      @include mq(hd){
        gap: 32px;
      }

      .form-input{
        width: 100%;
        position: relative;
        &-error{
          display: none;
          position: absolute;
          bottom: -40px;
          left: 0;
          margin: unset;
          color: #FF1A1A;
          font-family: 'IBM Plex Mono', sans-serif;
          @include get-font(12);
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 2.4px;
          @include mq(medium){
            @include get-font(16);
            line-height: 16px;
          }
          @include mq(hd){
            @include get-font(24);
            line-height: 32px;
          }

        }
        &.invalid{

          .form-input-error {
            display: block;
          }

          input{
            border-color: #FF1A1A;
          }
        }

        @include mq(tablet){
          width: 70%;
        }
      }

      input {
        border: unset;
        border-radius: unset;
        background: transparent;
        width: 100%;
        position: relative;
        border-bottom: 4px solid $white;
        padding: 1.5rem 2rem 1.5rem 1rem;
        color: $white;
        font-family: "IBM Plex Mono", sans-serif;
        @include get-font(18);
        line-height: 140%;
        font-weight: 400;
        letter-spacing: .54px;

        @include mq(mobile) {
          @include get-font(28);
        }

        @include mq(hd) {
          @include get-font(40);
        }

        &::placeholder {
          color: $white;
          opacity: .5;

        }

        &:focus-visible {
          outline: unset;
        }
      }
      .btn-primary {
        border-color: $white;
        margin-left: auto;
        background-color: #1FB2FB;
        color: $white;

        @include mq(medium) {
          //margin-right: 60px;
        }
        @include mq(tablet){
          margin: unset;

        }
        &:hover{
          border-color: #1FB2FB;
          background-color: #FFFFFF;
          color: #1FB2FB;
        }
        &:active{
          border-color: #FFFFFF;
          background-color: #1FB2FB;
          color: #FFFFFF;
        }
      }
    }

  }

  .comics-page{
    width: 100%;
    img{
      width: 100%;
      display: block;
    }
  }

}
